import {
    Component,
    OnInit,
} from '@angular/core';
import {
    Router,
    NavigationEnd
} from '@angular/router';
import {
    filter
} from 'rxjs/operators';
import {
    AuthService
} from './shared/auth.service';


@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
    title = "app";

    constructor(
        private router: Router,
        private authService: AuthService,
    ) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                localStorage.setItem("previousUrl", event.url);
            });
    }

    ngOnInit() {}

    isLoggedIn() {
        const loginCookie = localStorage.getItem("loggedIn");
        return loginCookie != null && loginCookie === "true";
    }
}
