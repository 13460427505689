import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { filter, subscribeOn } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(
		private authService: AuthService,
		private router: Router
	) {

	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (this.authService.isLoggedIn()) {
			return true;
		}
		this.authService.logoutUser()
		return false;
	  }
}
@Injectable({
	providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private router: Router
	) {
		
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (!this.authService.isLoggedIn()) {
			return true;
		}
		const previousUrl = localStorage.getItem('previousUrl')
		this.router.navigate([previousUrl])
		return false;
	  }
}