import { NgModule } from '@angular/core';
import { AnalyticsComponent } from './analytics.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AnalyticsRoutingModule } from './analytics-routing.module';
import { TimePipe } from 'app/shared/time.pipe';
import { SharedModule } from 'app/shared/shared.module';


@NgModule({
    declarations: [
        AnalyticsComponent,
        TimePipe
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AnalyticsRoutingModule,
        SharedModule
    ],
    exports: [SharedModule, AnalyticsComponent]
})
export class AnalyticsModule {}
