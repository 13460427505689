import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'gtmetrixGrade'
})
export class GtmetrixGradePipe implements PipeTransform {

    transform(value: any, args ? : any): any {
		if (Number(value)) {
			let score = +value;
			if (score < 50) {
				return "F";
			} else if (score > 50 && score < 60) {
				return "E";
			} else if (score > 60 && score < 70) {
				return "D";
			} else if (score > 70 && score < 80) {
				return "C";
			} else if (score > 80 && score < 90) {
				return "B";
			} else {
				return "A";
			}
		} else {
			return value
		}
    }

}
