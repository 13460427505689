import {
	Pipe,
	PipeTransform
} from '@angular/core';

@Pipe({
	name: 'gradeColor'
})
export class GradeColorPipe implements PipeTransform {

	transform(value: any, args ? : any): any {
		if (Number(value)) {
			if (value < 50) {
				return "#ed1d23";
			} else if (value >= 50 && value < 60) {
				return "#ef4037";
			} else if (value >= 60 && value < 70) {
				return "#f15a29";
			} else if (value >= 70 && value < 80) {
				return "#f7941c";
			} else if (value >= 80 && value < 90) {
				return "#15c269";
			} else {
				return "#00a651";
			}
		} else {
			return value
		}
	}

}
