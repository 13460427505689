import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from 'app/shared/users.service';
import { CheckPassword } from '../shared/validators/check-password.validator'
import { MatchFields } from '../shared/validators/match-fields.validator'
import { JwtHelper } from 'angular2-jwt';

let jwtHelper: JwtHelper = new JwtHelper()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  resetToken: string
  showForm: boolean = false
  submitted: boolean = false
  errors: Array<any>

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fBuilder: FormBuilder,
    private userService: UsersService
  ) { }

  ngOnInit() {
    this.errors = []
    this.resetPasswordForm = this.fBuilder.group({
      'newPassword': new FormControl(null, Validators.compose([Validators.required, CheckPassword])),
      'confirmPassword': new FormControl(null, Validators.compose([Validators.required, MatchFields('newPassword')]))
    })

    this.resetToken = this.route.snapshot.params['token']

    if (this.resetToken && !jwtHelper.isTokenExpired(this.resetToken)) {
      this.showForm = true
      const body = {
        tokenFor: 'forgot',
        token: this.resetToken
      }
      this.userService.checkToken(body).subscribe(data => {
        if (!data) {
          this.errors.push({ message: 'Invalid or expired password reset link' })
        }
      }, error => {
        if (error) {
          this.router.navigate(['/login/reset-invalid'])
          this.errors.push({ message: 'Invalid or expired password reset link' })
        }
      })      
    } else {
      this.router.navigate(['login/reset-invalid'])
      this.errors.push({ message: 'Invalid or expired password reset link' })
    }
  }

  onSubmit() {
    let isValid = this.resetPasswordForm.valid
    if (isValid) {
      let body = this.resetPasswordForm.value
      body.resetToken = this.resetToken
      
      this.userService.resetPassword(body).then(result => {
        this.submitted = true
      }).catch(err => {
        console.log(err)        
      })
    }
  }

  onLoginClick() {
    this.router.navigate(['/login'])
  }

}
