import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { PerformanceAPIService } from 'app/shared/performance.service';
import { Subscription } from 'rxjs';
import { LoadingScreenService } from 'app/shared/loading-screen.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-page-speed',
	templateUrl: './page-speed.component.html'
})
export class PageSpeedComponent implements OnInit, OnDestroy {
	opportunities: any[] = [];
	resultsPageSpeed: any
	pagespeedCategories: any[] = []
	subscription: Subscription
	refreshSubscription: Subscription

	@Input() projectId: string
	@Input() pageSpeedsDataExistsOnDatabase: boolean
	@Input() compactView: boolean = false

	constructor(
		private performanceService: PerformanceAPIService,
		private loadingScreenService: LoadingScreenService,
		private router: Router
	) { }

	ngOnInit() {
		this.refreshSubscription = this.performanceService.refreshExecuted.subscribe(message => {
			if (message === 'Refresh Executed') {
				this.fetchDataFromAPI()
			}
		})

		if (!this.compactView) {
			this.loadingScreenService.startLoading();
		}

		if (this.pageSpeedsDataExistsOnDatabase) {
			const projectId = this.projectId
			this.performanceService.fetchGtMetrixDetailsFromDatabase(projectId)
				.subscribe(result => {
					const results = result[0]
					this.resultsPageSpeed = results.pagespeeds
					this.findOpportunities()
					this.pagespeedCategories = [
						this.resultsPageSpeed.lighthouseResult.categories.performance,
						this.resultsPageSpeed.lighthouseResult.categories.accessibility,
						this.resultsPageSpeed.lighthouseResult.categories["best-practices"]
					]
					// this.loadingScreenService.stopLoading()
				})
		} else {
			this.fetchDataFromAPI()
		}
	}

	fetchDataFromAPI(): void {
		this.subscription = this.performanceService.resultsPageSpeed.subscribe((results) => {
			this.resultsPageSpeed = results.res
			this.findOpportunities()
			this.pagespeedCategories = [
				this.resultsPageSpeed.lighthouseResult.categories.performance,
				this.resultsPageSpeed.lighthouseResult.categories.accessibility,
				this.resultsPageSpeed.lighthouseResult.categories["best-practices"]
			]
			this.performanceService.savePageSpeedsDetails(this.projectId, this.resultsPageSpeed)
				.subscribe(result => {
					console.log(result)
				}, err => {
					console.log(err)
				})
		})
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe()
		}
		if (this.refreshSubscription) {
			this.refreshSubscription.unsubscribe()
		}
	}

	findOpportunities(): void {
		this.opportunities = []
		const audits = this.resultsPageSpeed.lighthouseResult.audits;
		for (let audit in audits) {
			if (audits[audit]['details']) {
				if (audits[audit]['details']['type']) {
					if (audits[audit]["details"]["type"] === "opportunity" && audits[audit]['score'] < 1) {
						console.log(`op :${audit}`);
						if (typeof audits[audit].details.overallSavingsMs === 'string') {
							audits[audit].details.overallSavingsMs = parseFloat(audits[audit].details.overallSavingsMs).toFixed(0)
						} else {
							audits[audit].details.overallSavingsMs = audits[audit].details.overallSavingsMs.toFixed(0)
						}
						this.opportunities.push(audits[audit])
					}
				}
			}
		}
		this.opportunities.sort((a, b) => {
			let result = 0
			if (+a.details.overallSavingsMs > +b.details.overallSavingsMs) {
				result = 1
			} else if (+a.details.overallSavingsMs < +b.details.overallSavingsMs) {
				result = -1
			} else {
				result = 0
			}
			return -result
		})
	}

	viewMore() {
		this.router.navigate(['/performance'])
	}

	onCompleteHandler() {
		this.loadingScreenService.stopLoading()
	}

	// Not in current use
	// toggleLighthouseCatergoires($event: any) {
	// 	if (this.lighthouseCategories.includes($event.target.value)) {
	// 		let index = this.lighthouseCategories.indexOf($event.target.value);
	// 		this.lighthouseCategories.splice(index, 1);
	// 	} else {
	// 		this.lighthouseCategories.push($event.target.value);
	// 	}
	// }

}
