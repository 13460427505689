import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Chart, ChartOptions, ChartConfiguration, ChartData, ChartDataSets } from 'chart.js';

@Component({
    selector: "app-chart-selector",
    templateUrl: "./chart-selector.component.html"
})
export class ChartSelectorComponent implements OnInit, OnChanges {
    @Input() id: string;
    chartId: string;
    @Input() title: string = "";
    @Input() value: string = "";
    @Input() results: any;
    @Input() isCompactView: boolean = false;
    @Input() dateRange: string;
    @Input() difference: string;
    @Input() inactive: boolean;
    @Input() negativeStatIsGood: boolean = false;
    chart: Chart;
    chartData: ChartData;
    options: ChartOptions;

    @Output() chartClicked: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {
        this.chartId = this.id + "-chart";
        this.refreshChart();
    }

    refreshChart() {
        this.chartData = this.generateChartDataset();
        this.options = this.getOptions();
        this.renderChart();
        this.preselectUsersChart();
    }

    ngOnChanges() {
        this.refreshChart();
    }

    preselectUsersChart() {
        if (this.id === "users") {
            this.selectChart();
        }
    }

    onClick(id, title): void {
        if (!this.isCompactView) {
            const chartId = id + "-chart";
            this.chartClicked.emit({ id: chartId, title: title });
            this.selectChart();
        }
    }

    selectChart() {
        let chartData = this.chartData;
        let borderColor = this.getColorPreset(this.id);
        let backgroundColor = this.getColorPreset(`${this.id}-bg`);
        chartData.datasets[0].borderColor = borderColor;
        chartData.datasets[0].backgroundColor = backgroundColor;
        if (this.chart) {
            this.chart.data = chartData;
            this.chart.update();
        }
    }

    getColorPreset(name: string): string {
        name = name.toLowerCase();
        switch (name) {
            case "clicks":
                return "#a3a0fb";
            case "clicks-bg":
                return "#e3e2fe";
            case "impressions":
                return "#ff4449";
            case "impressions-bg":
                return "#ffc7c8";
            case "ctr":
                return "#55d8fe";
            case "ctr-bg":
                return "#ccf3ff";
            case "position":
                return "#ffd983";
            case "position-bg":
                return "#fff4da";
            case "users":
                return "#A3A0FB";
            case "users-bg":
                return "rgba(163, 160, 251, .5)";
            case "newusers":
                return "#55D8FE";
            case "newusers-bg":
                return "rgba(85, 216, 254, .5)";
            case "sessions":
                return "#FF4449";
            case "sessions-bg":
                return "rgba(255, 68, 73, .5)";
            case "sessionsperuser":
                return "#FFD983";
            case "sessionsperuser-bg":
                return "rgba(255, 217, 131, .5)";
            case "pageviews":
                return "#009245";
            case "pageviews-bg":
                return "rgba(0, 146, 69, .5)";
            case "pagespersession":
                return "#F7931E";
            case "pagespersession-bg":
                return "rgba(247, 147, 30, .5)";
            case "avgsessionduration":
                return "#2C50EE";
            case "avgsessionduration-bg":
                return "rgba(44, 80, 238, .5)";
            case "bouncerate":
                return "#000000";
            case "bouncerate-bg":
                return "rgba(0, 0, 0, .5)";
            default:
                return "rgba(189, 204, 212, .5)";
        }
    }

    getOptions(): ChartOptions {
        return {
            maintainAspectRatio: false,
            responsive: true,
            layout: {
                padding: {
                    top: 60,
                    left: 0
                }
            },
            title: {
                display: false
            },
            events: ["click"],
            scales: {
                scaleLabel: {
                    display: false
                },
                gridLines: {
                    display: false
                },
                yAxes: [
                    {
                        display: false,
                        id: this.title,
                        gridLines: {
                            drawOnChartArea: false,
                            drawTicks: false
                        },
                        ticks: {
                            mirror: true
                        }
                    }
                ],
                xAxes: [
                    {
                        type: "time",
                        distribution: "series",
                        display: false,
                        time: {
                            unit: "day",
                            displayFormats: {
                                day: "MMM DD"
                            },
                            tooltipFormat: "ll"
                        },
                        gridLines: {
                            drawOnChartArea: false,
                            drawTicks: false
                        }
                    }
                ]
            },
            legend: {
                display: false
            }
        };
    }

    generateChartDataset(): ChartData {
        let chartData = {
            labels: [],
            datasets: []
        };
        let data = [];
        if (Array.isArray(this.results)) {
            this.results.forEach(result => {
                chartData.labels.push(result["label"]);
                data.push(result["data"]);
            });
        } else {
            data = this.results.data;
            chartData.labels = this.results.labels;
        }
        let compactViewOrInactive = this.isCompactView || this.inactive;
        let dataset = {
            data: data,
            label: this.title,
            color: "red",
            borderColor: compactViewOrInactive
                ? this.getColorPreset(this.id)
                : "#CCC",
            backgroundColor: compactViewOrInactive
                ? this.getColorPreset(`${this.id}-bg`)
                : "rgba(204, 204, 204, .5)",
            fill: true,
            lineTension: 0,
            pointRadius: 0,
            yAxisID: this.title,
            borderWidth: 2
        };
        chartData.datasets.push(dataset);
        chartData.labels.push(this.title);
        return chartData;
    }

    renderChart(): void {
        const config = {
            type: "line",
            data: this.chartData,
            options: this.options
        };
        Chart.defaults.global.defaultFontFamily = "Gotham Medium, sans-serif";
        setTimeout(() => {
            if (this.chart) {
                this.chart.destroy();
            }
            this.chart = new Chart(this.chartId, config);
        }, 100);
    }
}
