import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html'
})
export class ReportHeaderComponent implements OnInit {

  @Input() logoPath: string
  @Input() title: string
  @Input() description: string
  @Input() lastUpdated: string
  @Input() smallSpacing: boolean = false
  @Input() smallText: boolean = false

  subscription: Subscription

  constructor(private messageService: MessageService) {
    this.subscription = this.messageService.getMessage().subscribe(date => {
      this.lastUpdated = date
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

}
