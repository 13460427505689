import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-percent-circle',
  templateUrl: './percent-circle.component.html'
})
export class PercentCircleComponent implements OnInit {

  @Input() percentage: number
  @Input() id: string
  @Input() color: string

  @Output() onComplete: EventEmitter<any> = new EventEmitter<any>();

  canvasId: string

  constructor() { }

  ngOnInit() {
    this.drawCircle()
    // this.renderTitle()
  }

  drawCircle() {
    let id = this.id.replace(/\s/g,'').toLowerCase()
    this.canvasId = id

    let canvas = document.createElement('canvas')
    canvas.id = this.canvasId
    const circleRadius = 145
    canvas.width = circleRadius
    canvas.height = 225

    let parentContainer = document.getElementById('percentCircleContainer')
    parentContainer.appendChild(canvas)
    let percentage = this.percentage
    let radius = circleRadius / 2.2
    this.drawPercentageCircle(percentage, radius, canvas)
  }

  degreesToRadians(deg) {
    return (deg / 180) * Math.PI
  }

  percentToRadians(percentage) {
    // convert the percentage into degrees
    let degrees = percentage * 360 / 100
    // and so that arc begins at top of circle (not 90 degrees) we add 270 degrees
    return this.degreesToRadians(degrees + 270)
  }

  drawPercentageCircle(percentage, radius, canvas) {
    const context = canvas.getContext('2d')
    canvas.style.backgroundColor = 'white'

    const x = canvas.width / 2
    const y = canvas.height / 2
    const centerOffsetY = 15
    let startAngle = this.percentToRadians(0)
    let endAngle = this.percentToRadians(percentage)

    let counterClockwise = true

    context.beginPath()
    context.arc(x, y - centerOffsetY, radius, startAngle, endAngle, counterClockwise)
    context.lineCap = 'round'
    context.lineWidth = 12

    context.strokeStyle = '#EAEAEA'
    context.stroke()

    counterClockwise = false

    context.beginPath()
    context.arc(x, y - centerOffsetY, radius, startAngle, endAngle, counterClockwise)
    context.lineCap = 'round'
    context.lineWidth = 12

    context.strokeStyle = this.color
    context.stroke()

    // now draw the inner text
    context.font = "36px Gotham"
    context.fillStyle = "#09060C"
    context.textAlign = "center"
    context.fillText(percentage, x, y)

    // Now render the titles
    context.font = "15px Gotham"
    context.fillStyle = "#09060C"
    context.textAlign = "center"

    context.fillText(this.id, x, y * 1.9)

    this.onComplete.emit('completed')
  }

  renderTitle() {
    let parentContainer = document.getElementById('percentCircleTitle')
    let pElem = document.createElement('p')
    pElem.innerText = this.id
    parentContainer.appendChild(pElem)

  }
}
