import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { AuthenticatedGuard } from './shared/auth-guard.service';
import { SelectProjectComponent } from './select-project/select-project.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const appRoutes: Routes = [
	{ path: 'login', component: LoginComponent, canActivate: [AuthenticatedGuard] },
	{ path: 'select-project', component: SelectProjectComponent, },
	{ path: 'under-construction/:page-name', component: UnderConstructionComponent },
	{ path: 'under-construction-2/:page-name', component: UnderConstructionComponent },
	{ path: 'under-construction-3/:page-name', component: UnderConstructionComponent },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'reset-password/:token', component: ResetPasswordComponent },
	{ path: 'oauth2callback', redirectTo: '/home', pathMatch: 'full'},
	{ path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
	{ path: 'analytics', loadChildren: './analytics/analytics.module#AnalyticsModule'},
	{ path: 'performance', loadChildren: './performance/performance.module#PerformanceModule'},
	{ path: 'sales-data', loadChildren: './sales-data/sales-data.module#SalesDataModule'},
	{ path: 'organic-search', loadChildren: './organic-search/organic-search.module#OrganicSearchModule'},
	{ path: 'google-ads', loadChildren: './google-ads/google-ads.module#GoogleAdsModule'},
	{ path: '', redirectTo: '/login', pathMatch: 'full' },
]

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {

}
