import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnalyticsComponent } from './analytics.component';
import { AuthGuard } from 'app/shared/auth-guard.service';

const analyticsRoutes: Routes = [
	{
		path: '',
		component: AnalyticsComponent,
		canActivate: [AuthGuard]
	}
]


@NgModule({
	imports: [
		RouterModule.forChild(analyticsRoutes)
	],
	exports: [
		RouterModule
	]
})
export class AnalyticsRoutingModule {

}