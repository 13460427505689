import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { PerformanceAPIService } from 'app/shared/performance.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { LoadingScreenService } from 'app/shared/loading-screen.service';

@Component({
    selector: "app-gtmetrix",
    templateUrl: "./gtmetrix.component.html"
})
export class GtmetrixComponent implements OnInit, OnDestroy {
    gtmetrixBenchmark = {
        pagespeed: 72,
        yslow: 70,
        loadTime: 6.9,
        pageSize: 3.23,
        requests: 89
    };
    gtmetrixScreenshot: string;
    locationList: string[] = [];
    accountDetails: string;
    testRegion: string = "Sydney, Australia";
    testDate: any;
    resultsGtmetrix: any;
    url: string;
    subscription: Subscription;
    refreshSubscription: Subscription;

    @Input() projectId: string;
    @Input() dataExistsOnDatabase: boolean;
    @Input() showReportComponents: boolean = false;
    selectedProject: any;

    constructor(
        private performanceService: PerformanceAPIService,
        private loadingScreenService: LoadingScreenService
    ) {}

    ngOnInit() {
        const project = JSON.parse(localStorage.getItem("selectedProject"));
        if (project) {
            this.selectedProject = project;
        }
        this.refreshSubscription = this.performanceService.refreshExecuted.subscribe(
            message => {
                if (message === "Refresh Executed") {
                    this.fetchDataFromAPI();
                }
            }
        );

        if (this.dataExistsOnDatabase) {
            const projectId = this.projectId;
            this.performanceService
                .fetchGtMetrixDetailsFromDatabase(projectId)
                .subscribe(result => {
                    const results = result[0];
                    this.resultsGtmetrix = results.gtmetrix;
                    this.url = this.performanceService.url;
                    const strippedURL = this.stripURL(this.url);
                    this.gtmetrixScreenshot = `/assets/img/screenshots/${strippedURL}.png`;
                    this.testDate = results.testDate;

                    this.loadingScreenService.stopLoading();
                });
        } else {
            this.fetchDataFromAPI();
        }
    }

    fetchDataFromAPI(): void {
        this.subscription = this.performanceService.resultsGtmetrix.subscribe(
            results => {
                this.resultsGtmetrix = results.res;
                this.url = this.performanceService.url;
                const strippedURL = this.stripURL(this.url);
                this.gtmetrixScreenshot = `/assets/img/screenshots/${strippedURL}.png`;
                this.testDate = moment();

                this.performanceService
                    .saveGTMetrixDetails(
                        this.projectId,
                        this.resultsGtmetrix,
                        this.testDate,
                        this.testRegion
                    )
                    .subscribe(
                        result => {
                            console.log(result);
                        },
                        err => {
                            console.log(err);
                        }
                    );
            }
        );
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    stripURL(url): string {
        return url
            .replace("https://", "")
            .replace(".com", "")
            .replace(".au", "")
            .replace("/", "");
    }

    formatMB(bytes): string {
        const mb = bytes / Math.pow(1024, 2);
        return mb.toFixed(2);
    }
}
