import { NgModule } from "@angular/core";
import { PerformancePanelComponent } from './performance-panel/performance-panel.component';
import { CommonModule } from "@angular/common";
import { PerformanceRoutingModule } from "./performance-routing.module";
import { FormsModule } from '@angular/forms';
import { RoundProgressModule } from "angular-svg-round-progressbar";
import { GtmetrixComponent } from './gtmetrix/gtmetrix.component';
import { PageSpeedComponent } from './performance-panel/page-speed/page-speed.component';
import { GtmetrixGradePipe } from './gtmetrix-grade.pipe';
import { GradeColorPipe } from './grade-color.pipe';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
    declarations: [
        PerformancePanelComponent,
        GtmetrixComponent,
        GtmetrixGradePipe,
        GradeColorPipe,
        PageSpeedComponent
    ],
    imports: [
        CommonModule,
        PerformanceRoutingModule,
        FormsModule,
        RoundProgressModule,
        SharedModule
    ],
    exports: [SharedModule, PageSpeedComponent, GradeColorPipe]
})

export class PerformanceModule {

}
