import {
    NgModule
} from '@angular/core';
import {
    ChartSelectorComponent
} from './chart-selector/chart-selector.component';
import {
    DatePickerComponent
} from './date-picker/date-picker.component';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import {
    CommonModule
} from '@angular/common';
import {
    PageTitleComponent
} from './page-title/page-title.component';
import { RoundProgressModule } from "angular-svg-round-progressbar";
import { PercentCircleComponent } from './percent-circle/percent-circle.component';
import { ReportHeaderComponent } from './report-header/report-header.component';
import { ReportFooterComponent } from './report-footer/report-footer.component';

@NgModule({
    declarations: [
        ChartSelectorComponent,
        DatePickerComponent,
        PageTitleComponent,
        PercentCircleComponent,
        ReportHeaderComponent,
        ReportFooterComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RoundProgressModule
    ],
    exports: [
        ChartSelectorComponent,
        DatePickerComponent,
        PageTitleComponent,
        PercentCircleComponent,
        ReportHeaderComponent,
        ReportFooterComponent
    ]
})
export class SharedModule {}
