import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import * as moment from "moment";
import { EventEmitter, Output } from '@angular/core';


@Component({
    selector: "app-date-picker",
    templateUrl: "./date-picker.component.html"
})
export class DatePickerComponent implements OnInit {
    dateForm: FormGroup;
    dateRange: string = "This Month"
    presetOptions: string[] = [
        "Today",
        "7 Days",
        "This Month",
        "Last Month",
        "This Year"
    ];
    customDateOpen: boolean = false;
    @Output() dateChanged: EventEmitter<any> = new EventEmitter<any>()

    constructor(private fBuilder: FormBuilder) {}

    ngOnInit() {
        // Note: When month starts and we are in any day less than 5th day, display last month's data
        let day = new Date().getDate()
        if (day < 5) {
            this.dateRange = 'Last Month'
        }
        this.setDate(this.dateRange);
    }

    setDate(range: string): void {
        const now = moment()
        this.dateRange = range;
        switch (range) {
            case "Today":
                this.dateForm = this.fBuilder.group({
                    startDate: new FormControl(
                        moment(now)
                            .format("YYYY-MM-DD")
                    ),
                    endDate: new FormControl(moment(now).format("YYYY-MM-DD"))
                });
                break;
            case "7 Days":
                this.dateForm = this.fBuilder.group({
                    startDate: new FormControl(
                        moment(now)
                            .subtract(1, "week")
                            .format("YYYY-MM-DD")
                    ),
                    endDate: new FormControl(
                        moment(now)
                            .subtract(1, "day")
                            .format("YYYY-MM-DD")
                    )
                });
                break;
            case "This Month":
                this.dateForm = this.fBuilder.group({
                    startDate: new FormControl(
                        moment(now)
                            .startOf("month")
                            .format("YYYY-MM-DD")
                    ),
                    endDate: new FormControl(
                        moment(now)
                            .format("YYYY-MM-DD")
                    )
                });
                break;
            case "Last Month":
                this.dateForm = this.fBuilder.group({
                    startDate: new FormControl(
                        moment(now)
                            .subtract(1, "month")
                            .startOf("month")
                            .format("YYYY-MM-DD")
                    ),
                    endDate: new FormControl(
                        moment(now)
                            .subtract(1, "month")
                            .endOf("month")
                            .format("YYYY-MM-DD")
                    )
                });
                break;
            case "This Year":
                this.dateForm = this.fBuilder.group({
                    startDate: new FormControl(
                        moment(now)
                            .startOf("year")
                            .format("YYYY-MM-DD")
                    ),
                    endDate: new FormControl(
                        moment(now)
                            .format("YYYY-MM-DD")
                    )
                });
                break;
            default:
                break;
        }
        this.sendDate()
    }

    sendDate(): void {
        this.dateChanged.emit({ dateRange: this.dateRange, dateForm: this.dateForm })
        if (this.customDateOpen) {
            this.setCustomDisplayDate();
        }
        this.customDateOpen = false
    }

    openCustomForm(): void {
        this.customDateOpen = true
        this.setCustomDisplayDate()
    }

    setCustomDisplayDate(): void {
        const startDate = this.dateForm.value.startDate
        const startDateStr = this.getDisplayDate(startDate)
        const endDate = this.dateForm.value.endDate
        const endDateStr = this.getDisplayDate(endDate)
        this.dateRange = startDateStr + ' - ' + endDateStr
    }

    getDisplayDate(date: string): string {
        const vals = date.split('-')
        const year = vals[0]
        const month = vals[1]
        const day = vals[2]
        const displayDate = day + '/' + month + '/' + year
        return displayDate
    }

    clearCustomForm(): void {
        this.customDateOpen = false
        this.setDate('This Month')
    }

    displayCustomForm(): void {
        this.customDateOpen = true;
        this.dateRange = "Custom Date Range";
    }
}
