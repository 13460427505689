import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from '../message.service';


@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html'
})
export class PageTitleComponent implements OnInit, OnDestroy {

  @Input() pageTitle: string
  @Input() pageDescription: string
  @Input() lastUpdated: string
  @Output() refreshClicked = new EventEmitter()
  @Output() downloadClicked = new EventEmitter()

  subscription: Subscription

  constructor(private messageService: MessageService) {
    this.subscription = this.messageService.getMessage().subscribe(date => {
      this.lastUpdated = date
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  onRefresh() {
    this.refreshClicked.emit('Refresh')
  }

  onDownload() {
    this.downloadClicked.emit('Download')
  }

}
