import { Component, OnInit } from '@angular/core';
import { PerformanceAPIService } from "app/shared/performance.service";
import { LoadingScreenService } from "app/shared/loading-screen.service";
import { AuthService } from "app/shared/auth.service";
import { UsersService } from "app/shared/users.service";
import { MessageService } from "app/shared/message.service";
import * as moment from "moment";
import { Subscription } from "rxjs";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html"
})
export class DashboardComponent implements OnInit {
    url: string;
    projectId: string = null;
    loadingSubscriberPageSpeed: Subscription;
    pageSpeedsDataExistsOnDatabase = false;
    lastUpdated = "";
    pageSpeedsCheckComplete = false;
    lighthouseCategories: string[] = [
        "performance",
        "accessibility",
        "best-practices"
    ];
    pageTitle: string = "";
    pageDescription: string =
        "Rock Agency's monthly report combines data from multiple sources into an easy to read summary. Keep up to date on your websites Performance, Audience and Traffic Analytics and Organic Search results.";
    selectedProject: any;
    showReportComponents: boolean = false;
    cd: string;
    reportTitle: string;

    constructor(
        private performanceAPI: PerformanceAPIService,
        private loadingScreenService: LoadingScreenService,
        private authService: AuthService,
        private userService: UsersService,
        private messageService: MessageService
    ) {}

    ngOnInit() {
        this.refreshData();
    }

    getDataFromService(service, query: any = { url: this.url }): void {
        this.performanceAPI.getData(service, query);
    }

    // updateDate(): void {
    //     this.lastUpdated = moment().format("LLLL");
    //     this.messageService.setMessage(this.lastUpdated);
    // }

    getDataForPageSpeeds(): void {
        this.getDataFromService("page-speed", {
            url: this.url,
            categoriesArray: this.lighthouseCategories
        });
        this.loadingSubscriberPageSpeed = this.performanceAPI.resultsPageSpeed.subscribe(
            () => {
                // this.updateDate();
                this.loadingScreenService.stopLoading();
            }
        );
    }

    onDownloadReport() {
        this.loadingScreenService.startLoading();
        const domId = "DashboardReport";
        const fileName = "Dashboard_Report.pdf";
        this.showReportComponents = true;
        this.lastUpdated = moment().format("LLLL");
        setTimeout(() => {
            this.userService.generatePDF([domId], fileName).then(
                () => {
                    this.showReportComponents = false;
                    this.loadingScreenService.stopLoading();
                },
                err => {
                    this.showReportComponents = false;
                    this.loadingScreenService.stopLoading();
                }
            );
        }, 200);
    }

    refreshData() {
        this.loadingScreenService.startLoading();
        this.selectedProject = JSON.parse(
            localStorage.getItem("selectedProject")
        );
        this.cd = this.selectedProject.iconPath;
        this.reportTitle = this.selectedProject.name + " Summary Report";
        this.url = this.selectedProject.url;
        this.projectId = this.selectedProject._id;
        this.checkIfPageSpeedsDataExistsInDatabase(this.projectId);
        this.pageTitle =
            "Welcome " +
            JSON.parse(localStorage.getItem("selectedProject")).name;
    }

    checkIfPageSpeedsDataExistsInDatabase(projectId): void {
        this.performanceAPI
            .findPageSpeedsDetailsForProject(projectId)
            .subscribe(result => {
                const response = result;
                if (response.pagespeeds) {
                    this.pageSpeedsDataExistsOnDatabase = true;
                } else {
                    this.pageSpeedsDataExistsOnDatabase = false;
                }
                this.pageSpeedsCheckComplete = true;
                this.getDataForPageSpeeds();
            });
    }
}
