import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpHelperService } from "./http-helper.service";
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { JwtHelper } from 'angular2-jwt';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';

let jwtHelper: JwtHelper = new JwtHelper();

@Injectable({
    providedIn: "root"
})
export class AuthService {
    constructor(
        private router: Router,
        private http: Http,
        private httpHelperService: HttpHelperService
    ) {}

    getToken() {
        return AuthService.token;
    }

    static get token(): string {
        return localStorage.getItem("token");
    }

    static set token(token: string) {
        if (token) {
            localStorage.setItem("token", token);
        } else {
            localStorage.removeItem("token");
        }
    }

    invalidateToken() {
        AuthService.token = "";
    }

    loginUser(email: string, password: string) {
        const user = {
            email,
            password
        };
        return this.http
            .post("/api/auth/login", JSON.stringify(user), {
                headers: this.httpHelperService.headers()
            })
            .pipe(
                map((res: any) => {
                    let token = res.json().token;
                    AuthService.token = token;
                    return res.json();
                })
            );
    }

    isLoggedIn() {
        let token = AuthService.token;
        if (!!token) {
            try {
                let payload = jwtHelper.decodeToken(token);
                return !jwtHelper.isTokenExpired(token);
            } catch (error) {
                return false;
            }
        }
    }

    getCurrentUser() {
        return new Promise((resolve, reject) => {
            if (this.isLoggedIn()) {
                return this.http
                    .get("/api/userinfo", {
                        headers: this.httpHelperService.headers(
                            true,
                            AuthService.token
                        )
                    })
                    .subscribe((res: any) => {
                        resolve(res.json());
                    });
            } else {
                this.logoutUser()
                return Promise.resolve("false");
            }
        });
    }

    forgotPassword(email: string) {
        const payload = {
            email
        };
        return new Promise((resolve, reject) => {
            this.http.post('/api/forgotpassword', payload, {headers: this.httpHelperService.headers()})
            	.subscribe((res: any) => {
                    let result = res.json()
                    console.log(result);
                    
            		if (result) {
            			resolve(result)
            		} else {
            			reject(result)
            		}
            	})
        });
    }

    logoutUser() {
        this.setLoginCookie(false)
        this.clearSelectedProject()
        this.invalidateToken()
        this.router.navigate(["/login"]);
    }

    clearSelectedProject() {
        localStorage.setItem('selectedProject', '')
    }

    setLoginCookie(value: boolean): void {
        localStorage.setItem("loggedIn", value.toString());
    }

    handleErrors(error: Response) {
        return Observable.throw(error);
    }
}
