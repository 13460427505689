import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
	name: 'time'
})
export class TimePipe implements PipeTransform {

	transform(duration: any) {

		duration = Math.round(duration)
		let milliseconds = parseInt((duration % 1000) / 100 + '')
        let seconds: any = parseInt(duration % 60 + '')
        let minutes: any = parseInt((duration / 60) % 60 + '')
        let hours: any = parseInt((duration / (60 * 60)) % 24 + '')

      hours = (hours < 10) ? "0" + hours : hours
      minutes = (minutes < 10) ? "0" + minutes : minutes
      seconds = (seconds < 10) ? "0" + seconds : seconds

      return hours + ":" + minutes + ":" + seconds
	}
}