import {
	Component,
	OnInit,
	ElementRef,
	AfterViewInit,
	Input,
	QueryList,
	ViewChildren
} from '@angular/core';
import {
	AnalyticsService
} from 'app/shared/analytics.service';
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators
} from '@angular/forms';
import {
	Chart
} from 'chart.js';
import {
	LoadingScreenService
} from 'app/shared/loading-screen.service';
import {
	AuthService
} from 'app/shared/auth.service';
import {
	UsersService
} from 'app/shared/users.service';
import * as moment from "moment";
import { MessageService } from 'app/shared/message.service';
import { Router } from '@angular/router';
import { ChartSelectorComponent } from '../shared/chart-selector/chart-selector.component';

@Component({
    selector: "app-analytics",
    templateUrl: "./analytics.component.html"
})
export class AnalyticsComponent implements OnInit, AfterViewInit {
    @Input() compactView: boolean = false;

    @ViewChildren(ChartSelectorComponent) chartSelectors: QueryList<
        ChartSelectorComponent
    >;

    viewId: string = "";

    filterDate: string = "Day";

    result: any;
    result2: any;
    sessionData: any;

    acquisitionData: any;

    analysisForm: FormGroup;
    analysisForm2: FormGroup;

    compareDiff: any;

    public barChartOptions = {
        scaleShowVerticalLines: false,
        responsive: true
    };

    chart: Chart;
    chart2: Chart;
    mainChart: any;
    userTypeChart: Chart;
    usersChart: Chart;
    topChannelsChart: Chart;
    acquisitionUsersChart: Chart;
    acquisitionConversionsChart: Chart;

    allChartsData: any;

    pageTitle: string = "Website Analytics";

    pageDescription: string = `We’ve simplified your Google Analytics data and pulled together some key statistics to help you gather insights for marketing purposes. Discover how many users are interacting with your website, and learn where they’ve come from.`;

    lastUpdated: any;

    acquisitionTable: Array<any> = [];
    categoriesTable: Array<any> = [
        "Source",
        "Acquisition",
        "",
        "",
        "Behaviour",
        "",
        ""
    ];
    labelsTable: Array<any> = [
        "",
        "Users",
        "New Users",
        "Sessions",
        "Bounce Rate",
        "Pages/Session",
        "Avg. Session Duration"
    ];
    analyticsTotals: any = {
        users: '',
        newUsers: '',
        sessions: '',
        sessionsPerUser: '',
        pageViews: '',
        pagesPerSession: '',
        avgSessionDuration: '',
        bounceRate: ''
    };
    channelTotalsTable: string[] = [];
    selectedChart: string = "Users";
    previousSelection: string = "Users";
    trafficTable: Array<any> = [];
    trafficTotalsTable: Array<any> = [];
    noDataAvailable: boolean = false;
    date1Changed: boolean = false;
    dateRange: string;
    currentMonth: string;
    logoPath: string;
    showReportComponents: boolean = false;

    chartSelectorsList: Array<any> = [];

    constructor(
        private analyticsService: AnalyticsService,
        private fBuilder: FormBuilder,
        private elementRef: ElementRef,
        private loadingScreenService: LoadingScreenService,
        private authService: AuthService,
        private userService: UsersService,
        private messageService: MessageService,
        private router: Router
    ) {}

    ngOnInit() {
        this.loadingScreenService.startLoading();
        let now = moment();
        this.currentMonth = now.format("MMM");
        this.initDates();
        const project = JSON.parse(localStorage.getItem("selectedProject"));
        if (project) {
            this.viewId = project.googleAnalyticsId;
            this.logoPath = project.iconPath;
            if (!this.viewId) {
                this.noDataAvailable = true;
                this.loadingScreenService.stopLoading();
                return;
            }
            this.refreshData();
        }
    }

    ngAfterViewInit() {
        if (this.chartSelectors) {
            this.chartSelectors.changes.subscribe(c => {
                c.toArray().forEach(item => {
                    if (!this.arrayContains(this.chartSelectorsList, item)) {
                        this.chartSelectorsList.push(item);
                    }
                });
            });
        }
    }

    arrayContains(arr, obj) {
        let contains = false;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].title === obj.title) {
                contains = true;
                break;
            }
        }
        return contains;
    }

    getChartColours(chartTitle: string): any {
        let colors = {};
        switch (chartTitle) {
            case "Users":
                colors = {
                    borderColor: "#A3A0FB",
                    backgroundColor: "rgba(163, 160, 251, .5)"
                };
                break;
            case "New Users":
                colors = {
                    borderColor: "#55D8FE",
                    backgroundColor: "rgba(85, 216, 254, .5)"
                };
                break;
            case "Sessions":
                colors = {
                    borderColor: "#FF4449",
                    backgroundColor: "rgba(255, 68, 73, .5)"
                };
                break;
            case "Number of Sessions per User":
                colors = {
                    borderColor: "#FFD983",
                    backgroundColor: "rgba(255, 217, 131, .5)"
                };
                break;
            case "Page Views":
                colors = {
                    borderColor: "#009245",
                    backgroundColor: "rgba(0, 146, 69, .5)"
                };
                break;
            case "Pages / Session":
                colors = {
                    borderColor: "#F7931E",
                    backgroundColor: "rgba(247, 147, 30, .5)"
                };
                break;
            case "Avg. Session Duration":
                colors = {
                    borderColor: "#2C50EE",
                    backgroundColor: "rgba(44, 80, 238, .5)"
                };
                break;
            case "Bounce Rate":
                colors = {
                    borderColor: "#000000",
                    backgroundColor: "rgba(0, 0, 0, .5)"
                };
                break;
        }
        return colors;
    }

    initDates() {
        let now = moment();
        this.analysisForm = this.fBuilder.group({
            startDate: new FormControl(
                moment(now)
                    .startOf("month")
                    .format("YYYY-MM-DD")
            ),
            endDate: new FormControl(moment(now).format("YYYY-MM-DD"))
        });
        this.analysisForm2 = this.fBuilder.group({
            startDate: new FormControl(
                moment(now)
                    .startOf("month")
                    .format("YYYY-MM-DD")
            ),
            endDate: new FormControl(moment(now).format("YYYY-MM-DD"))
        });
    }

    updateDate(): void {
        this.lastUpdated = moment().format("LLLL");
        this.messageService.setMessage(this.lastUpdated);
    }

    onDateChange1(obj: any) {
        this.analysisForm = obj.dateForm;
        this.dateRange = obj.dateRange;
        this.date1Changed = true;
        this.applyDateFilter();
    }

    onDateChange2(obj: any) {
        this.analysisForm2 = obj.dateForm;
        this.applyAcquisitionDateFilter();
    }

    refreshData() {
        this.applyDateFilter();
        this.applyAcquisitionDateFilter();
    }

    onDownloadReport() {
        this.loadingScreenService.startLoading();
        const domIds = ["analyticsReport1", "analyticsReport2"];
        const fileName = "Analytics_Report.pdf";
        const reportType = "Analytics";
        this.showReportComponents = true;
        this.lastUpdated = moment().format("LLLL");
        setTimeout(() => {
            this.userService.generatePDF(domIds, fileName).then(
                () => {
                    this.showReportComponents = false;
                    this.loadingScreenService.stopLoading();
                },
                err => {
                    this.showReportComponents = false;
                    this.loadingScreenService.stopLoading();
                }
            );
        }, 300);
    }

    getTime(duration: any) {
        duration = Math.round(duration);
        let milliseconds = parseInt((duration % 1000) / 100 + "");
        let seconds: any = parseInt((duration % 60) + "");
        let minutes: any = parseInt(((duration / 60) % 60) + "");
        let hours: any = parseInt(((duration / (60 * 60)) % 24) + "");

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds;
    }

    applyDateFilter() {
        this.loadingScreenService.startLoading();
        this.getDataWithNoDimensions();
        this.getDataWithDimensions();
    }

    setAnalyticsTotals(data): any {
        return {
            users: data[0],
            newUsers: data[1],
            sessions: data[2],
            sessionsPerUser: data[3],
            pageViews: data[4],
            pagesPerSession: data[5],
            avgSessionDuration: data[6],
            bounceRate: data[7]
        };
    }

    getDataWithNoDimensions() {
        const viewId = this.viewId;
        if (!viewId) {
            return;
        }
        let startDate = this.analysisForm.value.startDate;
        let endDate = this.analysisForm.value.endDate;
        this.analyticsService
            .getData(viewId, startDate, endDate)
            .then((result: any) => {
                const data = result.data;
                this.result = data;
                const totals = data.rows[0];
                this.analyticsTotals = this.setAnalyticsTotals(totals);
                this.compareDiff = {};
                if (this.dateRange === "Last Month") {
                    this.currentMonth = moment(
                        this.analysisForm.value.startDate
                    ).format("MMM");
                    const startDate2 = moment(this.analysisForm.value.startDate)
                        .subtract(1, "month")
                        .format("YYYY-MM-DD");
                    const endDate2 = moment(this.analysisForm.value.endDate)
                        .subtract(1, "month")
                        .format("YYYY-MM-DD");
                    this.analyticsService
                        .getData(viewId, startDate2, endDate2)
                        .then((result: any) => {
                            const data = result.data;
                            const keys = Object.keys(
                                this.result.totalsForAllResults
                            );
                            for (let i = 0; i < keys.length; i++) {
                                const key = keys[i];
                                this.compareDiff[
                                    key
                                ] = this.getPercentageChange(
                                    data.totalsForAllResults[key],
                                    this.result.totalsForAllResults[key]
                                ).toString();
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    getPercentageChange(oldNumber, newNumber) {
        let decreaseValue = newNumber - oldNumber;
        return Math.round((decreaseValue / oldNumber) * 100);
    }

    setFilter(filter) {
        this.loadingScreenService.startLoading();
        this.filterDate = filter;
        this.getDataWithDimensions();
    }

    getDataWithDimensions() {
        const viewId = this.viewId;
        if (!viewId) {
            return;
        }
        const startDate = this.analysisForm.value.startDate;
        const endDate = this.analysisForm.value.endDate;
        let filterDate = this.filterDate;
        this.analyticsService
            .getDataWithDimensions(viewId, startDate, endDate, filterDate)
            .then((result: any) => {
                const data = result.data;

                this.result2 = data;

                this.renderMainChart(
                    data,
                    this.mainChart,
                    "mainChartCanvas",
                    2,
                    "Users"
                );
                this.renderAllMiniCharts(data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    renderMainChart(
        result: any,
        chartObjectId: Chart,
        chartId: string,
        dataIndex: number = 2,
        label: string
    ) {
        var self = this;
        if (this.compactView) {
            return;
        }
        let tempLabels = [];
        let tempHourLabels = [];
        let tempData = [];

        result.rows.forEach(row => {
            tempLabels.push(row[0]);
            if (this.filterDate === "Hourly") {
                tempHourLabels.push(row[1]);
            }
            tempData.push(row[dataIndex]);
        });
        let labels = [];
        tempLabels.forEach(label => {
            let year = label.substring(0, 4);
            let month = label.substring(4, 6);
            month = parseInt(month) - 1;
            let day = label.substring(6, 8);

            let date = new Date();
            date.setFullYear(year);
            date.setMonth(month);
            date.setDate(day);
            let d = moment(date).format("MMM DD");
            labels.push(date);
        });
        let hourLabels = [];
        if (this.filterDate === "Hourly") {
            tempHourLabels.forEach(label => {
                let hours = parseInt(label);
                let date = new Date();
                date.setHours(hours);
                let d = moment(date).format("HH:mm");
                hourLabels.push(d);
            });
        }

        let chartData = {
            labels: this.filterDate === "Hourly" ? hourLabels : labels,
            datasets: [
                {
                    data: tempData,
                    label: label,
                    borderColor: "#A3A0FB",
                    backgroundColor: "rgba(163, 160, 251, .5)",
                    fill: true,
                    tension: 0,
                    pointRadius: this.dateRange === "This Year" ? 0 : 5,
                    pointBackgroundColor: "#A3A0FB"
                }
            ]
        };
        let options: any = {
            maintainAspectRatio: false,
            title: {
                display: false
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [
                    {
                        type: "time",
                        distribution: "series",
                        display: true,
                        time: {
                            unit:
                                this.dateRange === "This Year"
                                    ? "month"
                                    : "day",
                            displayFormats:
                                this.dateRange === "This Year"
                                    ? { month: "MMM" }
                                    : { day: "MMM DD" },
                            tooltipFormat: "ll"
                        }
                    }
                ],
                yAxes: [
                    {
                        display: true,
                        ticks: {
                            beginAtZero: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: "Users"
                        }
                    }
                ]
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem) {
                        let value: any = tooltipItem.yLabel;
                        let isDecimal = false;
                        if (typeof value === "string") {
                            if (value.indexOf(".") > -1) {
                                value = parseFloat(value);
                            } else {
                                value = parseInt(value);
                            }
                        } else if (typeof value === "number") {
                            let temp = value + "";
                            if (temp.indexOf(".") > -1) {
                                isDecimal = true;
                            } else {
                                isDecimal = false;
                            }
                        }
                        let val: any = value;
                        if (isDecimal) {
                            val = val.toFixed(2);
                        }
                        switch (self.selectedChart) {
                            case "Avg. Session Duration":
                                val = self.getTime(val);
                                break;
                            case "Bounce Rate":
                                val = val + "%";
                                break;
                        }
                        return val;
                    }
                }
            }
        };
        if (this.filterDate === "Hourly") {
            options.scales = {
                xAxes: [
                    {
                        // type: 'time',
                        time: {
                            parser: "HH:mm",
                            unit: "hour",
                            displayFormats: {
                                hour: "HH:mm"
                            }
                        }
                    }
                ],
                yAxes: [
                    {
                        display: true
                    }
                ]
            };
        }
        let canvas = document.getElementById(chartId) as HTMLCanvasElement;
        let ctx = canvas.getContext("2d");
        if (this.mainChart) {
            this.mainChart.destroy();
            this.mainChart = new Chart(chartId, {
                type: "line",
                data: chartData,
                options: options
            });
        } else {
            this.mainChart = new Chart(chartId, {
                type: "line",
                data: chartData,
                options: options
            });
        }
    }

    renderAllMiniCharts(result: any) {
        let rows = result.rows;
        let labels = [];
        let hourLabels = [];
        let chartIds = [
            "",
            "",
            "users-chart",
            "newUsers-chart",
            "sessions-chart",
            "sessionsPerUser-chart",
            "pageViews-chart",
            "pagesPerSession-chart",
            "avgSessionDuration-chart",
            "bounceRate-chart"
        ];
        let dataObj = {};

        let usersDataArray: any = [];
        let newUsersDataArray: any = [];
        let sessionsDataArray: any = [];
        let sessionPerUserDataArray: any = [];
        let pageViewsDataArray: any = [];
        let pagesPerSessionDataArray: any = [];
        let avgSessionDurationDataArray: any = [];
        let bounceRateDataArray: any = [];

        rows.forEach(row => {
            let label = row[0];
            let year = label.substring(0, 4);
            let month = label.substring(4, 6);
            month = parseInt(month) - 1;
            let day = label.substring(6, 8);

            let date = new Date();
            date.setFullYear(year);
            date.setMonth(month);
            date.setDate(day);
            labels.push(date);

            usersDataArray.push(row[2]);
            newUsersDataArray.push(row[3]);
            sessionsDataArray.push(row[4]);
            sessionPerUserDataArray.push(row[5]);
            pageViewsDataArray.push(row[6]);
            pagesPerSessionDataArray.push(row[7]);
            avgSessionDurationDataArray.push(row[8]);
            bounceRateDataArray.push(row[9]);
        });

        if (this.filterDate === "Hourly") {
            rows.forEach(row => {
                let hours = parseInt(row[1]);
                let date = new Date();
                date.setHours(hours);
                let d = moment(date).format("HH:mm");
                hourLabels.push(d);
            });
        }

        dataObj["users-chart"] = {
            data: usersDataArray,
            labels: this.filterDate === "Hourly" ? hourLabels : labels
        };
        dataObj["newUsers-chart"] = {
            data: newUsersDataArray,
            labels: this.filterDate === "Hourly" ? hourLabels : labels
        };
        dataObj["sessions-chart"] = {
            data: sessionsDataArray,
            labels: this.filterDate === "Hourly" ? hourLabels : labels
        };
        dataObj["sessionsPerUser-chart"] = {
            data: sessionPerUserDataArray,
            labels: this.filterDate === "Hourly" ? hourLabels : labels
        };
        dataObj["pageViews-chart"] = {
            data: pageViewsDataArray,
            labels: this.filterDate === "Hourly" ? hourLabels : labels
        };
        dataObj["pagesPerSession-chart"] = {
            data: pagesPerSessionDataArray,
            labels: this.filterDate === "Hourly" ? hourLabels : labels
        };
        dataObj["avgSessionDuration-chart"] = {
            data: avgSessionDurationDataArray,
            labels: this.filterDate === "Hourly" ? hourLabels : labels
        };
        dataObj["bounceRate-chart"] = {
            data: bounceRateDataArray,
            labels: this.filterDate === "Hourly" ? hourLabels : labels
        };

        this.allChartsData = dataObj;

        if (this.date1Changed) {
            this.previousSelection = "Users";
            this.selectedChart = "Users";
            this.date1Changed = false;
            this.loadingScreenService.stopLoading();
        }
    }

    onChartClick(chartObj) {
        let previousChartSelected = this.chartSelectorsList.find(item => {
            return item.title === this.previousSelection;
        });
        if (previousChartSelected) {
            let chartData = previousChartSelected.chartData;
            chartData.datasets[0].borderColor = "#CCC";
            chartData.datasets[0].backgroundColor = "rgba(204, 204, 204, .5)";
            let chart = previousChartSelected.chart;
            if (chart) {
                chart.data = chartData;
                chart.update();
            }
        }

        let data = this.allChartsData[chartObj.id].data;
        let labels = this.allChartsData[chartObj.id].labels;
        if (data.length !== labels.length) {
            labels = labels.slice(0, data.length);
        }
        this.selectedChart = chartObj.title;
        let chartData = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    label: chartObj.title,
                    borderColor: this.getChartColours(this.selectedChart)
                        .borderColor,
                    backgroundColor: this.getChartColours(this.selectedChart)
                        .backgroundColor,
                    fill: true,
                    tension: 0,
                    pointRadius: this.dateRange === "This Year" ? 0 : 5,
                    pointBackgroundColor: this.getChartColours(
                        this.selectedChart
                    ).borderColor
                }
            ]
        };

        this.mainChart.data = chartData;
        this.mainChart.options.scales.yAxes[0].scaleLabel.labelString =
            chartObj.title;
        this.mainChart.update();
        this.previousSelection = this.selectedChart;
    }

    applyAcquisitionDateFilter() {
        this.loadingScreenService.startLoading();
        this.getAcquisitionData();
        this.getTrafficSources();
    }

    getTrafficSources() {
        const viewId = this.viewId;
        if (!viewId) {
            return;
        }
        let startDate = this.analysisForm2.value.startDate;
        let endDate = this.analysisForm2.value.endDate;
        this.analyticsService
            .getTrafficSources(viewId, startDate, endDate)
            .then((result: any) => {
                const data = result.data;
                const dataCopy = Object.assign({}, data);
                this.extractTrafficData(dataCopy);
            })
            .catch(err => {
                console.log(err);
            });
    }

    getAcquisitionData() {
        const viewId = this.viewId;
        if (!viewId) {
            return;
        }
        let startDate = this.analysisForm2.value.startDate;
        let endDate = this.analysisForm2.value.endDate;
        this.analyticsService
            .getAcquisitionData(viewId, startDate, endDate)
            .then((result: any) => {
                const data = result.data;
                this.acquisitionData = data;
                const dataCopy = Object.assign({}, data);
                this.extractChannelTableData(dataCopy);
            })
            .catch(err => {
                console.log(err);
            });
    }

    extractChannelTableData(data) {
        const rows = data.rows;
        if (!rows || !this.result) {
            this.loadingScreenService.stopLoading();
            return;
        }
        let tableData = [];
        rows.forEach(row => {
            const obj = {
                label: row[0],
                users: row[1],
                newUsers: row[2],
                sessions: row[3],
                bounceRate: row[4],
                pagesPerSession: row[5],
                avgSessionDuration: row[6],
                usersWidth: this.getCategoryWidth(
                    row[1],
                    data.totalsForAllResults["ga:users"]
                ),
                bounceRateWidth: this.getCategoryWidth(row[4], 100)
            };
            tableData.push(obj);
        });

        tableData.sort((a, b) => {
            let result = 0;
            if (+a.users > +b.users) {
                result = 1;
            } else if (+a.users < +b.users) {
                result = -1;
            } else {
                result = 0;
            }
            return -result;
        });
        this.channelTotalsTable = this.createTableTotalsArray(
            data.totalsForAllResults
        );
        this.acquisitionTable = tableData;
        this.loadingScreenService.stopLoading();
    }

    createTableTotalsArray(data: any[]): string[] {
        return [
            "",
            data["ga:users"],
            data["ga:newUsers"],
            data["ga:sessions"],
            `${Number(data["ga:bounceRate"]).toFixed(2)}%`,
            `${Number(data["ga:pageviewsPerSession"]).toFixed(2)}`,
            `${this.getDuration(data["ga:avgSessionDuration"])}`
        ];
    }

    extractTrafficData(data) {
        const rows = data.rows;
        if (!rows || !this.result) {
            this.loadingScreenService.stopLoading();
            return;
        }

        this.trafficTotalsTable = this.createTableTotalsArray(
            data.totalsForAllResults
        );

        let tableData = [];
        rows.forEach(row => {
            let obj = {
                label: row[0],
                users: row[1],
                newUsers: row[2],
                sessions: row[3],
                bounceRate: row[4],
                pagesPerSession: row[5],
                avgSessionDuration: row[6]
            };
            tableData.push(obj);
        });
        tableData.sort((a, b) => {
            let result = 0;
            if (+a.users > +b.users) {
                result = 1;
            } else if (+a.users < +b.users) {
                result = -1;
            } else {
                result = 0;
            }
            return -result;
        });
        this.trafficTable = tableData;
    }

    getDuration(duration: any) {
        duration = Math.round(duration);
        let seconds: any = parseInt((duration % 60) + "");
        let minutes: any = parseInt(((duration / 60) % 60) + "");
        let hours: any = parseInt(((duration / (60 * 60)) % 24) + "");

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds;
    }

    getPercentage(category, value) {
        let percent = "";
        let total = 0;
        value = parseFloat(value);
        switch (category) {
            case "users":
                total = this.trafficTotalsTable[1];
                percent = ((value / total) * 100).toFixed(2) + "%";
                break;
            case "new users":
                total = this.trafficTotalsTable[2];
                percent = ((value / total) * 100).toFixed(2) + "%";
                break;
            case "sessions":
                total = this.trafficTotalsTable[3];
                percent = ((value / total) * 100).toFixed(2) + "%";
                break;
        }
        return percent;
    }

    getCategoryClass(label) {
        let str = "";
        switch (label) {
            case "Social":
                str = "social";
                break;
            case "Direct":
                str = "direct";
                break;
            case "Referral":
                str = "referral";
                break;
            case "Organic Search":
                str = "organic";
                break;
            case "Paid Search":
                str = "paid";
                break;
        }
        return str;
    }

    getCategoryWidth(value, maxValue) {
        const barWidth = 200;
        return Math.round((value / maxValue) * barWidth);
    }

    viewMore() {
        this.router.navigate(["/analytics"]);
    }
}
