import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Http, Response } from "@angular/http";
import { HttpHelperService } from "app/shared/http-helper.service";

@Injectable({
    providedIn: "root"
})
export class OrganicSearchService {
    constructor(
        private http: Http,
        private httpHelperService: HttpHelperService
    ) {}
    searchConsoleAPI = "/api/search-console";
    private headers = {
        headers: this.httpHelperService.headers()
	};
	dateResults = new Subject<any>()
    queryResults = new Subject<any>()
    previousDateResults = new Subject<any>()
    previousQueryResults = new Subject<any>()

    postAPI(query, subscriptionRequest) {
        let subscription
        switch (subscriptionRequest) {
            case 'query':
                subscription = this.queryResults
                break;
            case 'date':
                subscription = this.dateResults
                break
            case 'previousDateMonth':
                subscription = this.previousDateResults
                break;
            case 'previousQueryMonth':
                subscription = this.previousQueryResults
                break;
        }
        this.http.post(this.searchConsoleAPI, query, this.headers).subscribe(
            (res: Response) => {
                subscription.next(res.json());
            },
            (err: any) => {
                subscription.next({error: err});
            }
        );
    }
}
