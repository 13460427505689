import { Component, OnInit, Input, IterableDiffer, IterableDiffers, OnDestroy, AfterViewInit, OnChanges } from '@angular/core';
import { Chart, ChartData, ChartOptions, ChartYAxe } from 'chart.js';
import { Subscription, Observable } from 'rxjs';


@Component({
	selector: 'app-organic-search-chart',
	templateUrl: './chart.component.html'
})
export class OrganicSearchChartComponent implements OnInit, OnDestroy, OnChanges  {
	chartObject: any
	chartId: string;
	categories: string[] = ['clicks']
	@Input() dateResults: any = [];
	categoriesSubscription: Subscription
	@Input() categoriesObservable: Observable<string[]>

	constructor() {
	}
	ngOnInit() {
		this.categoriesSubscription = this.categoriesObservable.subscribe((categories) => {
				this.categories = categories
				this.updateMap()
			}
		)
		this.renderMap()
	}

	ngOnChanges() {
		this.chartObject ? this.updateMap() : this.renderMap()
	}

	ngOnDestroy() {
		this.categoriesSubscription.unsubscribe()
	}

	generateChartOptions(): ChartOptions {
		let options: any = {
			showTooltips: true,
			maintainAspectRatio: false,
			tooltips: {
				enabled: true
			},
			title: {
				display: false
			},
			events: ["click"],
			scales: {},
			legend: {
				display: false
			}
		};
		options.scales.yAxes = this.getYAxesOptionsForCategories();
		options.scales.xAxes = [
            {
                type: "time",
                distribution: "series",
                display: true,
                time: {
                    unit: "day",
                    displayFormats: {
                        day: "MMM DD"
                    },
                    tooltipFormat: "ll"
                },
                gridLines: {
                    drawOnChartArea: false
                }
            }
        ];
		return options;
	}

	getYAxesOptionsForCategories(): ChartYAxe[] {
		let yAxesOptions = [];
		if (this.categories.length) {
			this.categories.forEach((category, index) => {
				let config: any = {
					id: category,
					ticks: {
						min: 0
					},
					scaleLabel: {
						labelString: category,
						display: true,
					},
					events: ["click"]
				};
				if (index === 0) {
					config.position = "left";
				} else if (index === 1) {
					config.position = "right";
				} else {
					config.display = false;
				}
				if (category === "position") {
					config.ticks = {
						reverse: true,
						min: 0
					};
				}
				if (this.categories.length >= 3) {
					config.display = false;
				}
				yAxesOptions.push(config);
			});
		}
		return yAxesOptions;
	}

	updateMap(): void {
		this.chartObject.data = this.generateChartDataset();
		console.log(this.chartObject.data);
		this.chartObject.options = this.generateChartOptions();
		this.chartObject.update();
	}

	generateChartDataset(): ChartData {
		let chartData = {
			labels: [],
			datasets: []
		};
		this.categories.forEach(category => {
			let dataArray = [];
			this.dateResults.forEach(item => {
				dataArray = [...dataArray, ...item[category]];
				if (!chartData.labels.includes(item.keys[0])) {
					chartData.labels.push(item.keys[0]);
				}
			});
			let dataset = {
				data: dataArray,
				label: category,
				borderColor: this.getCategoryColor(category),
				backgroundColor: this.getCategoryColor(category),
				fill: false,
				lineTension: 0,
				pointRadius: 0,
				yAxisID: category
			};
			chartData.datasets.push(dataset);
		});
		return chartData;
	}

	getCategoryColor(category): string {
		switch (category) {
			case "clicks":
				return "#a3a0fb";
			case "impressions":
				return "#ff4449";
			case "ctr":
				return "#55d8fe";
			case "position":
				return "#ffd983";
			default:
				return "rgba(189, 204, 212, .5)";
		}
	}

	renderMap(): void {
		let config = {
			type: "line",
			data: this.generateChartDataset(),
			options: this.generateChartOptions()
		};
		console.log(config);


		Chart.defaults.global.defaultFontColor = 'rgba(0, 0, 0, 0.5)';
		Chart.defaults.global.defaultFontFamily = "Gotham Medium, sans-serif";
		this.chartObject = new Chart("organic-chart", config);
	}
}
