import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-report-footer',
  templateUrl: './report-footer.component.html'
})
export class ReportFooterComponent implements OnInit {

    @Input() pageClass: string

  constructor() { }

  ngOnInit() {
  }

}
