import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AnalyticsService } from './shared/analytics.service';
import { HttpHelperService } from './shared/http-helper.service';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { FooterComponent } from './footer/footer.component';
import { LoadingScreenComponent } from './shared/loading-screen/loading-screen.component';
import { SelectProjectComponent } from './select-project/select-project.component';

// THIRD PARTY
import { ChartsModule } from 'ng2-charts';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { GtagModule } from "angular-gtag";


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        LoginComponent,
        ForgotPasswordComponent,
        UnderConstructionComponent,
        FooterComponent,
        LoadingScreenComponent,
        SelectProjectComponent,
        ResetPasswordComponent
    ],
    imports: [
        BrowserModule,
        HttpModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        ChartsModule,
        SharedModule,
        DashboardModule,
        GtagModule.forRoot({
            trackingId: "UA-68683292-5",
            trackPageviews: true
        })
    ],
    providers: [AnalyticsService, HttpHelperService],
    bootstrap: [AppComponent]
})
export class AppModule {}
