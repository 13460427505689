import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs';
import { OrganicSearchService } from 'app/shared/organic-search.service';
import * as moment from 'moment';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { LoadingScreenService } from 'app/shared/loading-screen.service';
import { MessageService } from 'app/shared/message.service';
import { Router } from '@angular/router';
import { UsersService } from 'app/shared/users.service';
import { DatePickerComponent } from 'app/shared/date-picker/date-picker.component';

@Component({
    selector: "app-search-console",
    templateUrl: "./search-console.component.html"
})
export class SearchConsoleComponent implements OnInit, OnDestroy {
    dateSubscription: Subscription;
    previousDateSubscription: Subscription;
    querySubscription: Subscription;
    previousQuerySubscription: Subscription;

    ctr: number;
    clicks: number;
    impressions: number;
    position: number;
    previousMonthCtr: number;
    previousMonthClicks: number;
    previousMonthImpressions: number;
    previousMonthPosition: number;
    positionDifference: string;
    ctrDifference: string;
    impressionsDifference: string;
    clicksDifference: string;

    dateResults = [];
    previousDateResults = [];
    queryResults = [];
    previousQueryResults = [];

    categories: string[] = ["clicks"];
    categoriesObservable = new Subject<string[]>();
    searchConsoleForm: FormGroup;

    url: string = "";
    lastUpdated: any;
    showMore: boolean = false;

    clicksResults: any;
    ctrResults: any;
    impressionsResults: any;
    positionResults: any;

    project: any;
    dateRange: string;
    warningMessage: boolean = false;
    @Input() compactView: boolean = false;
    @Input() showReportComponents: boolean = false;

    pageTitle: string = "Website Organic Search";
    pageDescription: string = `Discover how many Clicks your website is receiving from search engines, and gather insights into the Search Terms and Keywords that users are typing into search engines to find you. Note, this data does not include advertised search terms.`;

    noDataAvailable: boolean = false;
    logoPath: string;

    @ViewChild(DatePickerComponent) datePicker: DatePickerComponent;

    constructor(
        private organicSearchService: OrganicSearchService,
        private loadingScreenService: LoadingScreenService,
        private messageService: MessageService,
        private router: Router,
        private userService: UsersService,
        private fBuilder: FormBuilder
    ) {}

    ngOnInit() {
        this.refreshData();
    }

    refreshData() {
        this.loadingScreenService.startLoading();
        this.initializeSubscriptions();
        const project = JSON.parse(localStorage.getItem("selectedProject"));
        if (project) {
            this.url = project.search_console_url;
            this.logoPath = project.iconPath;
        }
        this.getData();
    }

    initializeSubscriptions(): void {
        this.querySubscription = this.organicSearchService.queryResults.subscribe(
            results => {
                if (results.error) {
                    this.warningMessage = true;
                    this.loadingScreenService.stopLoading();
                }
                if (results.rows != undefined) {
                    this.queryResults = results.rows;
                    this.queryResults = this.queryResults.slice(0, 50);
                    this.refineQueryData();
                    this.calculateStats();
                    this.updateDate();
                    this.loadingScreenService.stopLoading();
                } else {
                    this.noDataAvailable = true;
                    this.loadingScreenService.stopLoading();
                }
            }
        );
        this.previousQuerySubscription = this.organicSearchService.previousQueryResults.subscribe(
            results => {
                if (results.rows != undefined) {
                    this.previousQueryResults = results.rows;
                    this.previousQueryResults = this.previousQueryResults.slice(
                        0,
                        50
                    );
                    this.refineQueryData(true);
                    this.calculateStats(true);
                }
            }
        );
        this.dateSubscription = this.organicSearchService.dateResults.subscribe(
            results => {
                if (results.rows != undefined) {
                    this.dateResults = results.rows;
                    this.calculateStats();
                    this.updateDate();
                    this.processCategories();
                    console.log('date res', results)
                    let res = this.sumOrAverageCalc('ctr', this.dateResults, true)
                    console.log(res)
                    this.loadingScreenService.stopLoading();
                }
            }
        );
        this.previousDateSubscription = this.organicSearchService.previousDateResults.subscribe(
            results => {
                if (results.rows != undefined) {
                    this.previousDateResults = results.rows;
                    this.calculateStats(true);
                    this.processCategories();
                    this.loadingScreenService.stopLoading();
                }
            }
        );
    }

    onDownloadReport() {
        this.loadingScreenService.startLoading();
        const domId = "OrganicSearch";
        const fileName = "OrganicSearch_Report.pdf";
        this.showReportComponents = true;
        this.lastUpdated = moment().format("LLLL");
        setTimeout(() => {
            this.userService.generatePDF([domId], fileName).then(
                () => {
                    this.showReportComponents = false;
                    this.loadingScreenService.stopLoading();
                },
                err => {
                    this.showReportComponents = false;
                    this.loadingScreenService.stopLoading();
                }
            );
        }, 300);
    }

    toggleCategory(category): void {
        if (this.categories.includes(category)) {
            this.categories = this.categories.filter(x => x != category);
        } else {
            this.categories.push(category);
        }
        this.categoriesObservable.next(this.categories);
    }

    processCategories() {
        this.impressionsResults = this.groupResultsByCategory("impressions");
        this.ctrResults = this.groupResultsByCategory("ctr");
        this.clicksResults = this.groupResultsByCategory("clicks");
        this.positionResults = this.groupResultsByCategory("position");
    }

    updateDate(): void {
        this.lastUpdated = moment().format("LLLL");
        this.messageService.setMessage(this.lastUpdated);
    }

    groupResultsByCategory(category: string): any {
        return this.dateResults.map(result => {
            return {
                label: result.keys[0],
                data: result[category]
            };
        });
    }

    refineQueryData(previousMonth = false): void {
        if (previousMonth) {
            this.previousQueryResults.forEach(query => {
                query["queryString"] = query.keys[0];
            });
        } else {
            this.queryResults.forEach(query => {
                query["queryString"] = query.keys[0];
            });
        }
    }

    onDateChange(obj: any): void {
        this.searchConsoleForm = obj.dateForm;
        this.dateRange = obj.dateRange;
        this.getData();
    }

    ngOnDestroy() {
        this.dateSubscription.unsubscribe();
        this.querySubscription.unsubscribe();
    }

    getData(): void {
        this.dateResults = [];
        this.queryResults = [];
        this.postAPI("query");
        this.postAPI("date");
        if (this.dateRange === "Last Month") {
            this.postAPI("date", [], "previousDateMonth");
            this.postAPI("query", [], "previousQueryMonth");
        }
    }

    postAPI(
        dimension,
        filterGroup = [],
        subscriptionRequest = dimension
    ): void {
        if (!this.searchConsoleForm) {
            return;
        }
        let startDate, endDate;
        if (
            subscriptionRequest === "previousDateMonth" ||
            subscriptionRequest === "previousQueryMonth"
        ) {
            startDate = moment(this.searchConsoleForm.value.startDate)
                .subtract(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD");
            endDate = moment(this.searchConsoleForm.value.endDate)
                .subtract(1, "month")
                .endOf("month")
                .format("YYYY-MM-DD");
        } else {
            startDate = this.searchConsoleForm.value.startDate;
            endDate = this.searchConsoleForm.value.endDate;
        }
        const requestQuery: any = {
            startDate: startDate,
            endDate: endDate,
            dimensions: [dimension]
        };
        if (filterGroup) {
            requestQuery.dimensionFilterGroups = filterGroup;
        }
        const query = {
            url: this.url,
            resource: requestQuery
        };
        this.organicSearchService.postAPI(query, subscriptionRequest);
    }

    postAPIWithFilter(filter): void {
        this.dateResults = [];
        this.queryResults = [];
        const filterGroup = [
            {
                filters: [
                    {
                        dimension: "query",
                        expression: filter,
                        operator: "equals"
                    }
                ]
            }
        ];
        this.postAPI("query", filterGroup);
        this.postAPI("date", filterGroup);
    }

    sumOrAverageCalc(target, array, calcAverage = false): number {
        let sum = 0;
        array.forEach(item => {
            sum += item[target];
        });
        return calcAverage ? +(sum / array.length).toFixed(2) : sum;
    }

    getPercentageChange(oldNumber, newNumber) {
        let decreaseValue = newNumber - oldNumber;
        return Math.round((decreaseValue / oldNumber) * 100).toString();
    }

    calculateDifference(): void {
        this.positionDifference = this.getPercentageChange(this.previousMonthPosition, this.position)
        this.clicksDifference = this.getPercentageChange(this.previousMonthClicks, this.clicks)
        this.impressionsDifference = this.getPercentageChange(this.previousMonthImpressions, this.impressions)
        // Flipped values this because it's a percentage
        this.ctrDifference = this.getPercentageChange(this.ctr, this.previousMonthCtr)
    }

    calculateStats(previousMonth = false): void {
        if (previousMonth) {
            if (this.previousQueryResults.length === 1) {
                this.previousMonthPosition = +this.previousQueryResults[0][
                    "previousMonthPosition"
                ].toFixed(2);
            } else {
                this.previousMonthPosition = this.sumOrAverageCalc(
                    "position",
                    this.previousDateResults,
                    true
                );
            }
            this.previousMonthCtr = this.sumOrAverageCalc(
                "ctr",
                this.previousDateResults,
                true
            );
            this.previousMonthClicks = this.sumOrAverageCalc(
                "clicks",
                this.previousDateResults
            );
            this.previousMonthImpressions = this.sumOrAverageCalc(
                "impressions",
                this.previousDateResults
            );
        } else {
            if (this.queryResults.length === 1) {
                this.position = +this.queryResults[0]["position"].toFixed(2);
            } else {
                this.position = this.sumOrAverageCalc(
                    "position",
                    this.dateResults,
                    true
                );
            }
            this.ctr = this.sumOrAverageCalc("ctr", this.dateResults, true);
            this.clicks = this.sumOrAverageCalc("clicks", this.dateResults);
            this.impressions = this.sumOrAverageCalc(
                "impressions",
                this.dateResults
            );
        }
        if (this.dateRange === "Last Month") {
            this.calculateDifference();
        }
    }

    viewMore(): void {
        this.router.navigate(["/organic-search"]);
    }
}
