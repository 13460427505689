import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "app/shared/auth-guard.service";
import { SearchConsoleComponent } from './search-console/search-console.component';

const seoRoutes: Routes = [
    {
        path: "",
        component: SearchConsoleComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(seoRoutes)],
    exports: [RouterModule]
})
export class OrganicSearchRoutingModule {}
