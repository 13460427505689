import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'app/shared/auth.service';
import { Router } from '@angular/router';
import { CheckEmail } from '../shared/validators/check-email.validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup
  submitted: boolean = false

  constructor(
    private fBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.fBuilder.group({
      'email': new FormControl(null, Validators.compose([Validators.required, CheckEmail]))
    })
  }

  onSubmit() {
    if (this.forgotPasswordForm.valid) {
      const email = this.forgotPasswordForm.value.email
      this.authService.forgotPassword(email).then(result => {
        this.submitted = true;
      }).catch(error => {

      })
    }
  }

  onLoginClick() {
    this.router.navigate(['/login'])
  }

}
