import {
	Injectable
} from '@angular/core';
import {
	Http,
	Response
} from '@angular/http';
import {
	HttpHelperService
} from './http-helper.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LoadingScreenService } from "app/shared/loading-screen.service";

@Injectable({
	providedIn: "root"
})
export class PerformanceAPIService {
	resultsGtmetrix = new Subject<any>();
	resultsPageSpeed = new Subject<any>();
	gtmetrixAccountDetails = new Subject<any>();
	gtmetrixLocationList = new Subject<any>();
	refreshExecuted = new Subject<string>();
	url: string
	projectId: string
	dataExistsOnDatabase: boolean = false

	constructor(
		private http: Http,
		private httpHelperService: HttpHelperService,
		private loadingScreenService: LoadingScreenService
	) {}

	private headers = {
		headers: this.httpHelperService.headers()
	};

	gtmetrixAPI = `api/gtmetrix`;
	gtmetrixAPIAccount = `api/gtmetrix/account-details`;
	gtmetrixAPILocationList = `api/gtmetrix/location-list`;
	googlePageSpeedApi = `api/google-page-speed`;
	gtmetrixSaveDetailsAPI = `api/gtmetrix/save-details`;
	googlePageSpeedSaveDetailsApi = `/api/pagespeeds/save-details`


	getData(api: string, query: any = {}) {
		this.url = query.url
		this.projectId = query.projectId
		switch (api) {
			case "account-details":
				return this.getAPI(this.gtmetrixAPIAccount).then((res) => {
					const obj = {
						res: res,
						projectId: this.projectId
					}
					this.gtmetrixAccountDetails.next(obj)
				}).catch(err => console.log)
			case "location-list":
				return this.getAPI(this.gtmetrixAPILocationList).then((res) => {
					const obj = {
						res: res,
						projectId: this.projectId
					}
					this.gtmetrixLocationList.next(obj)
				}).catch(err => console.log)
			case "gtmetrix":
				return this.postAPI(this.gtmetrixAPI, query).then((res) => {
					const obj = {
						res: res,
						projectId: this.projectId
					}
					this.resultsGtmetrix.next(obj)
				}).catch(err => console.log)
			case "page-speed":
				return this.postAPI(this.googlePageSpeedApi, query).then((res) => {
					const obj = {
						res: res,
						projectId: this.projectId
					}
					this.resultsPageSpeed.next(obj)
				}).catch(err => console.log(err))
		}
	}

	postAPI(internalAPI: string, query: any) {
		return new Promise((resolve, reject) => {
			this.http.post(internalAPI, query, this.headers)
				.subscribe(
					(res: Response) => {
						console.log(res.json());
						resolve(res.json());
					},
					(err: any) => {
						this.loadingScreenService.stopLoading()
						reject(err);
					}
			);
		});
	}

	getAPI(internalAPI: string) {
		return new Promise((resolve, reject) => {
			this.http.get(internalAPI, this.headers)
				.subscribe(
					(res: Response) => {
						console.log(`service sending ${res}`);
						resolve(res.json());
					},
					(err: any) => {
						this.loadingScreenService.stopLoading()
						reject(err);
					}
				);
		});
	}

	saveGTMetrixDetails(projectId, gtmetrixResults, testDate, testRegion) {
		const payload = {
			projectId,
			gtmetrixResults,
			testDate,
			testRegion
		}
		return this.http.post(this.gtmetrixSaveDetailsAPI, payload, { headers: this.httpHelperService.headers(true, AuthService.token) })
			.pipe(
				map((res: any) => {
					return res.json()
				})
			)
	}

	savePageSpeedsDetails(projectId, pagespeedsResults) {
		const payload = {
			projectId,
			pagespeedsResults
		}
		return this.http.post(this.googlePageSpeedSaveDetailsApi, payload, { headers: this.httpHelperService.headers(true, AuthService.token) })
			.pipe(
				map((res: any) => {
					return res.json()
				})
			)
	}

	findGTMetrixDetailsForProject(projectId) {
		const url = `/api/gtmetrix/find-project/${projectId}`
		return this.http.get(url, { headers: this.httpHelperService.headers(true, AuthService.token) })
			.pipe(
				map((res: any) => {
					return res.json()
				})
			)
	}

	findPageSpeedsDetailsForProject(projectId) {
		const url = `/api/pagespeeds/find-project/${projectId}`
		return this.http.get(url, { headers: this.httpHelperService.headers(true, AuthService.token) })
			.pipe(
				map((res: any) => {
					return res.json()
				})
			)
	}

	fetchGtMetrixDetailsFromDatabase(projectId) {
		const url = `/api/gtmetrix/fetch-details/${projectId}`
		return this.http.get(url, { headers: this.httpHelperService.headers(true, AuthService.token) })
			.pipe(
				map((res: any) => {
					return res.json()
				})
			)
	}

	onRefresh() {
		this.refreshExecuted.next('Refresh Executed')
	}
}
