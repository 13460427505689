import {
	Component,
	OnInit,
	OnDestroy,
	Input
} from '@angular/core';
import { PerformanceAPIService } from 'app/shared/performance.service';
import { LoadingScreenService } from 'app/shared/loading-screen.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'app/shared/auth.service';
import { UsersService } from 'app/shared/users.service';
import * as moment from "moment";
import { MessageService } from 'app/shared/message.service';

@Component({
    selector: "app-performance-panel",
    templateUrl: "./performance-panel.component.html"
})
export class PerformancePanelComponent implements OnInit, OnDestroy {
    resultsGtmetrix: any;
    resultsPageSpeed: any;
    url: string;
    loadingSubscriberGtmetrix: Subscription;
    loadingSubscriberPageSpeed: Subscription;
    lighthouseCategories: string[] = [
        "performance",
        "accessibility",
        "best-practices"
    ];
    projectId: string = null;
    dataExistsOnDatabase: boolean = false;
    pageSpeedsDataExistsOnDatabase: boolean = false;
    databaseCheckComplete: boolean = false;
    pageSpeedsCheckComplete: boolean = false;

    pageTitle: string = "Website Performance";
    pageDescription: string =
        "Your website performance can have a significant effect on how your website is ranked by search engines. The performance analysis below combines data from multiple sources to highlight areas that can be improved.";

    lastUpdated: any;
    reportTitle: string = this.pageTitle;
    logoPath: string;
    @Input() showReportComponents: boolean = false;

    constructor(
        private performanceAPI: PerformanceAPIService,
        private loadingScreenService: LoadingScreenService,
        private authService: AuthService,
        private userService: UsersService,
        private messageService: MessageService
    ) {}

    ngOnInit() {
        this.loadingScreenService.startLoading();
        this.authService.getCurrentUser().then((currentUser: any) => {

            const selectedProject = JSON.parse(
                localStorage.getItem("selectedProject")
            );
            if (selectedProject) {
                this.projectId = selectedProject._id;
                this.url = selectedProject.url;
                this.logoPath = selectedProject.iconPath;
                this.checkIfDataExistsInDatabase(this.projectId);
                this.checkIfPageSpeedsDataExistsInDatabase(this.projectId);
            }
        });
    }

    updateDate(): void {
        this.lastUpdated = moment().format("LLLL");
        this.messageService.setMessage(this.lastUpdated);
    }

    ngOnDestroy() {
        if (this.loadingSubscriberGtmetrix) {
            this.loadingSubscriberGtmetrix.unsubscribe();
        }
        if (this.loadingSubscriberPageSpeed) {
            this.loadingSubscriberPageSpeed.unsubscribe();
        }
    }

    refreshData(): void {
        this.loadingScreenService.startLoading();
        this.dataExistsOnDatabase = false;
        this.pageSpeedsDataExistsOnDatabase = false;
        this.databaseCheckComplete = true;
        this.pageSpeedsCheckComplete = true;
        this.getDataForGTMetrix();
        this.getDataForPageSpeeds();
        this.updateDate();
        this.performanceAPI.onRefresh();
    }

    onDownloadReport() {
        this.loadingScreenService.startLoading();
        const domId = "PerformanceReport";
        const fileName = "Performance_Report.pdf";
        this.showReportComponents = true;
        this.lastUpdated = moment().format("LLLL");
        setTimeout(() => {
            this.userService.generatePDF([domId], fileName).then(
                () => {
                    this.showReportComponents = false;
                    this.loadingScreenService.stopLoading();
                },
                err => {
                    this.showReportComponents = false;
                    this.loadingScreenService.stopLoading();
                }
            );
        }, 300);
    }

    getDataFromService(service, query: any = { url: this.url }): void {
        this.performanceAPI.getData(service, query);
    }

    getDataForGTMetrix(): void {
        this.getDataFromService("gtmetrix", {
            url: this.url,
            projectId: this.projectId
        });
        this.loadingSubscriberGtmetrix = this.performanceAPI.resultsGtmetrix.subscribe(
            () => {
                this.updateDate();
                this.loadingScreenService.stopLoading();
            }
        );
    }

    getDataForPageSpeeds(): void {
        this.getDataFromService("page-speed", {
            url: this.url,
            categoriesArray: this.lighthouseCategories
        });
        this.loadingSubscriberPageSpeed = this.performanceAPI.resultsPageSpeed.subscribe(
            () => {
                this.updateDate();
                this.loadingScreenService.stopLoading();
            }
        );
    }

    checkIfDataExistsInDatabase(projectId): void {
        this.performanceAPI
            .findGTMetrixDetailsForProject(projectId)
            .subscribe(result => {
                const response = result[0];
                if (response && response._id) {
                    this.dataExistsOnDatabase = true;
                } else {
                    this.dataExistsOnDatabase = false;
                }
                this.databaseCheckComplete = true;
                this.getDataForGTMetrix();
            });
    }

    checkIfPageSpeedsDataExistsInDatabase(projectId): void {
        this.performanceAPI
            .findPageSpeedsDetailsForProject(projectId)
            .subscribe(result => {
                const response = result;
                if (response.pagespeeds) {
                    this.pageSpeedsDataExistsOnDatabase = true;
                } else {
                    this.pageSpeedsDataExistsOnDatabase = false;
                }

                this.pageSpeedsCheckComplete = true;
                this.getDataForPageSpeeds();
            });
    }
}
