import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchConsoleComponent } from './search-console/search-console.component';
import { OrganicSearchRoutingModule } from './organic-search-routing.module';
import { OrganicSearchChartComponent } from './chart/chart.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
    declarations: [SearchConsoleComponent, OrganicSearchChartComponent],
    imports: [
        CommonModule,
        OrganicSearchRoutingModule,
        ReactiveFormsModule,
        SharedModule
    ],
    exports: [SharedModule, SearchConsoleComponent, OrganicSearchChartComponent]
})
export class OrganicSearchModule {}
