import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpHelperService } from "./http-helper.service";
import { AuthService } from './auth.service';
import { map, catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
import { Subject, Observable } from 'rxjs';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { LoadingScreenService } from './loading-screen.service';
import * as moment from "moment";
import { resolve } from 'path';

@Injectable({
    providedIn: "root"
})
export class UsersService {
    currentProject: any;
    currentUser: any;

    private projectSelectionChanged = new Subject<any>();

    constructor(
        private http: Http,
        private httpHelperService: HttpHelperService,
        private authService: AuthService,
        private router: Router,
        private loadingScreenService: LoadingScreenService
    ) {}

    getProjectsForUser(userId) {
        const url = `/api/projects/${userId}`;
        return this.http
            .get(url, {
                headers: this.httpHelperService.headers(true, AuthService.token)
            })
            .pipe(
                map((res: any) => {
                    return res.json();
                }),
                catchError(this.handleErrors)
            );
    }

    setCurrentProject(project) {
        this.currentProject = project;
        localStorage.setItem("selectedProject", JSON.stringify(project));
        this.projectSelectionChanged.next(project);
    }

    getCurrentProject() {
        return this.currentProject;
    }

    onSelectProjectAndRedirect(project): void {
        this.setCurrentProject(project);
        this.projectSelectionChanged.next(project);
        this.authService.setLoginCookie(true);

        this.router.navigate(["/dashboard"]);
    }

    getUserProjects(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.authService.getCurrentUser().then((currentUser: any) => {
                if (currentUser && currentUser.length == undefined) {
                    this.currentUser = currentUser;
                }
                const userId = this.currentUser._id;
                this.getProjectsForUser(userId).subscribe((projects: any) => {
                    if (projects && projects.length > 0) {
                        resolve(projects);
                    } else {
                        reject("No projects found");
                    }
                });
            });
        });
    }

    getSelectedProject(): Observable<any> {
        return this.projectSelectionChanged.asObservable();
    }

    checkToken(data: any) {
        const body = data;
        return this.http
            .post("/api/check-token", body, {
                headers: this.httpHelperService.headers(false)
            })
            .pipe(
                map(res => {
                    return res.json();
                }),
                catchError(this.handleErrors)
            );
    }

    resetPassword(user) {
        return new Promise((resolve, reject) => {
            const body = user;
            this.http
                .post("/api/resetpassword", body, {
                    headers: this.httpHelperService.headers(false)
                })
                .subscribe((res: any) => {
                    const result = res.json();
                    if (result) {
                        resolve(result);
                    } else {
                        reject(result);
                    }
                });
        });
    }

    handleErrors(error: Response) {
        return Observable.throw(error);
    }

    convertImgToBase64(img) {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);

        return canvas.toDataURL("image/jpeg");
    }

    findPosition(obj) {
        let currLeft = 0;
        let currTop = 0;

        if (obj.offsetParent) {
            do {
                currLeft += obj.offsetLeft;
                currTop += obj.offsetTop;
            } while (obj == obj.offsetParent);
        }
        return {
            X: currLeft,
            Y: currTop
        };
    }

    generatePDFFileName(fileName: string): string {
        if (fileName.indexOf(".pdf")) {
            fileName = fileName.substring(0, fileName.indexOf(".pdf"));
        }
        let timestamp = moment().format();
        return fileName += "_" + timestamp + ".pdf";
    }

    generatePDF(domIds: Array<string>, fileName: string) {
        return new Promise((resolve, reject) => {
            const canvasConfig = {
                useCORS: true,
                backgroundColor: "#f0f0f0"
            };
            const updatedFileName = this.generatePDFFileName(fileName)
            let pdf = new jspdf();
            for (let index = 0; index < domIds.length; index++) {
                let elem = document.getElementById(domIds[index]);
                html2canvas(elem, canvasConfig)
                    .then(canvas => {
                        let imgData = canvas.toDataURL("image/jpeg");
                        let imgWidth = 210;
                        console.log(`Height: ${canvas.height}, Width: ${canvas.width}`)
                        let imgHeight = (canvas.height * imgWidth) / canvas.width;
                        if (index > 0) {
                            pdf.addPage();
                        }
                        return pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
                    })
                    .then(() => {
                        if (index === domIds.length - 1) {
                            pdf.save(updatedFileName)
                            resolve();
                        }
                    });
            }
        });
    }

    generatePDFNew(
        reportType: string,
        domIds: Array<string>,
        fileName: string
    ) {
        switch (reportType) {
            case "Analytics":
                this.generateAnalyticsReport(domIds, fileName);
                break;
        }
    }

    generateAnalyticsReport(domIds: Array<string>, fileName: string) {
        try {
            var doc = new jspdf("p", "mm");
            this.generateAnalyticsPDFAsync(doc, domIds).then(() => {
                doc.save(fileName);
                this.loadingScreenService.stopLoading();
            });
        } catch (error) {
            console.log("Error while generating PDF: " + error);
            this.loadingScreenService.stopLoading();
        }
    }

    generateAnalyticsPDFAsync(doc, domIds) {
        let sequence = Promise.resolve();
        domIds.forEach(domId => {
            sequence = sequence
                .then(() => {
                    let elem = document.getElementById(domId);
                    if (domId === "AnalyticsReport2") {
                        doc.addPage();
                    }
                    return html2canvas(elem, { useCORS: true });
                })
                .then(canvas => {
                    try {
                        let imgData = canvas.toDataURL("image/jpeg");
                        let imgWidth = 210;
                        let pageHeight = 295;
                        let imgHeight =
                            (canvas.height * imgWidth) / canvas.width;
                        let heightLeft = imgHeight;
                        let position = 10;
                        doc.addImage(
                            imgData,
                            "JPEG",
                            0,
                            position,
                            imgWidth,
                            imgHeight
                        );
                        heightLeft -= pageHeight;

                        while (heightLeft >= 0) {
                            position = heightLeft - imgHeight;
                            doc.addPage();
                            doc.addImage(
                                imgData,
                                "JPEG",
                                0,
                                position,
                                imgWidth,
                                imgHeight
                            );
                            heightLeft -= pageHeight;
                        }
                    } catch (error) {
                        console.log("Error while generating PDF: " + error);
                        this.loadingScreenService.stopLoading();
                    }
                });
        });
        return sequence;
    }
}
