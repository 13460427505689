import { NgModule } from '@angular/core';
import { SalesDataComponent } from './sales-data.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SalesDataRoutingModule } from './sales-data-routing.module';
import { SharedModule } from 'app/shared/shared.module';


@NgModule({
	declarations: [
		SalesDataComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		SalesDataRoutingModule,
		SharedModule
	],
	exports: [
		SharedModule,
		SalesDataComponent
	]
})
export class SalesDataModule {

}
