import { Injectable } from "@angular/core";
import { Http, Response } from "@angular/http";
import { HttpHelperService } from "./http-helper.service";
import { AuthService } from './auth.service';

@Injectable()
export class AnalyticsService {

	constructor(private http: Http, private httpHelperService: HttpHelperService) {}

	getData(viewId, startDate, endDate) {
		const headers = this.httpHelperService.headers()
		return new Promise((resolve, reject) => {
			this.http.get(`/api/analytics/${viewId}/${startDate}/${endDate}`, {headers: this.httpHelperService.headers(true, AuthService.token)})
				.subscribe((res: Response) => {
					var result = res.json()
					resolve(result)
				}, (err: any) => {
					reject(err)
				})
		}).catch(err => {
			console.log(err)
		})		
	}

	getDataWithDimensions(viewId, startDate, endDate, timeDimension) {
		return new Promise((resolve, reject) => {
			this.http.get(`/api/analytics2/${viewId}/${startDate}/${endDate}/${timeDimension}`, {headers: this.httpHelperService.headers(true, AuthService.token)})
				.subscribe((res: Response) => {
					var result = res.json()
					resolve(result)
				}, (err: any) => {
					reject(err)
				})
		}).catch(err => {
			console.log(err)
		})
	}

	getSessions(viewId, startDate, endDate) {
		return new Promise((resolve, reject) => {
			this.http.get(`/api/sessions/${viewId}/${startDate}/${endDate}`, {headers: this.httpHelperService.headers(true, AuthService.token)})
				.subscribe((res: Response) => {
					var result = res.json()
					resolve(result)
				}, (err: any) => {
					reject(err)
				})
		}).catch(err => {
			console.log(err)
		})
	}

	getSessionsData(viewId, startDate, endDate) {
		return new Promise((resolve, reject) => {
			this.http.get(`/api/sessions-data/${viewId}/${startDate}/${endDate}`, {headers: this.httpHelperService.headers(true, AuthService.token)})
				.subscribe((res: Response) => {
					var result = res.json()
					resolve(result)
				}, (err: any) => {
					reject(err)
				})
		}).catch(err => {
			console.log(err)
		})
	}

	getUserTypeDistribution(viewId, startDate, endDate) {
		return new Promise((resolve, reject) => {
			this.http.get(`/api/analytics/usertype-dist/${viewId}/${startDate}/${endDate}`, {headers: this.httpHelperService.headers(true, AuthService.token)})
				.subscribe((res: Response) => {
					var result = res.json()
					resolve(result)
				}, (err: any) => {
					reject(err)
				})
		}).catch(err => {
			console.log(err)
		})
	}

	getAcquisitionData(viewId, startDate, endDate) {
		return new Promise((resolve, reject) => {
			this.http.get(`/api/analytics/acquisition-data/${viewId}/${startDate}/${endDate}`, {headers: this.httpHelperService.headers(true, AuthService.token)})
				.subscribe((res: Response) => {
					var result = res.json()
					resolve(result)
				}, (err: any) => {
					reject(err)
				})
		}).catch(err => {
			console.log(err)
		})
	}

	getAcquisitionUsersAndConversions(viewId, startDate, endDate) {
		return new Promise((resolve, reject) => {
			this.http.get(`/api/analytics/acquisition-users-conversions/${viewId}/${startDate}/${endDate}`, {headers: this.httpHelperService.headers(true, AuthService.token)})
				.subscribe((res: Response) => {
					var result = res.json()
					resolve(result)
				}, (err: any) => {
					reject(err)
				})
		}).catch(err => {
			console.log(err)
		})
	}

	getTrafficSources(viewId, startDate, endDate) {
		return new Promise((resolve, reject) => {
			this.http.get(`/api/analytics/acquisition-traffic-sources/${viewId}/${startDate}/${endDate}`, {headers: this.httpHelperService.headers(true, AuthService.token)})
				.subscribe((res: Response) => {
					var result = res.json()
					resolve(result)
				}, (err: any) => {
					reject(err)
				})
		}).catch(err => {
			console.log(err)
		})
	}
}