import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "app/shared/shared.module";
import { OrganicSearchModule } from 'app/organic-search/organic-search.module';
import { SalesDataModule } from 'app/sales-data/sales-data.module';
import { AnalyticsModule } from 'app/analytics/analytics.module';
import { PerformanceModule } from 'app/performance/performance.module';

@NgModule({
    declarations: [DashboardComponent],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        ReactiveFormsModule,
        SharedModule,
        OrganicSearchModule,
        SalesDataModule,
        AnalyticsModule,
        PerformanceModule
    ],
    exports: [SharedModule]
})
export class DashboardModule {}
