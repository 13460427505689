import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'app/shared/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { UsersService } from 'app/shared/users.service';
import { Subscription } from 'rxjs';

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html"
})
export class HeaderComponent implements OnInit, OnDestroy {
    headerBackground = "assets/img/DigitalReportingDashboard-Header.jpg";
    user = {
      logo:"assets/img/DigitalReportingDashboard-Calbee.png",
      profileImage: "assets/img/user-login.png",
      name: "Calbee"
    }

    isChildActive: boolean = false
    currentUser: any
    selectedProject: any
    projects: Array<any>
    projectChangedSubscription: Subscription

    constructor(
        private authService: AuthService,
        private router: Router,
        private userService: UsersService
    ) {
        this.projectChangedSubscription = this.userService.getSelectedProject().subscribe(project => {
            this.selectedProject = project
        })
    }

    ngOnInit() {
        this.authService.getCurrentUser().then((currentUser: any) => {
            if (currentUser && currentUser.length == undefined) {
                this.currentUser = currentUser

                const userId = this.currentUser._id
                this.userService.getProjectsForUser(userId).subscribe(projects => {
                    if (projects && projects.length > 0) {
                        this.projects = projects
                    }
                    if (projects && projects.length === 1) {
                        this.selectedProject = projects[0]
                    } else {
                        this.selectedProject = JSON.parse(localStorage.getItem('selectedProject'))
                    }
                })
            }
        })
    }

    ngOnDestroy() {
        if (this.projectChangedSubscription) {
            this.projectChangedSubscription.unsubscribe()
        }
    }

    selectProject() {
        document.getElementById('SwitchDashboardOverlay').style.display = 'block'
        document.getElementById('AppContainer').classList.add('blur')
    }

    onLogout() {
        this.authService.logoutUser();
    }

    childLinkClicked() {
        this.isChildActive = true
    }
}
