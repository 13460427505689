import { Component, OnInit } from '@angular/core';
import { UsersService } from "app/shared/users.service";
import {Location} from '@angular/common';
import { AuthService } from 'app/shared/auth.service';

@Component({
    selector: "app-select-project",
    templateUrl: "./select-project.component.html"
})
export class SelectProjectComponent implements OnInit {
    selectedProject: any;
    currentProject: any;
    currentUser: any;
    projects: any;

    constructor(
        private userService: UsersService,
        private location: Location,
        private authService: AuthService
    ) {
        if (localStorage.getItem('selectedProject')) {
            this.currentProject = JSON.parse(localStorage.getItem('selectedProject'))
        }
    }

    ngOnInit() {
		this.userService.getUserProjects()
			.then(projects => this.checkProjects(projects))
    }

    onSelectProject(project): void {
        this.userService.onSelectProjectAndRedirect(project)
        this.userService.setCurrentProject(project)
        localStorage.setItem('selectedProject', JSON.stringify(project))
        if (window.location.pathname !== '/select-project') {
            window.location.reload()
        }
    }

    checkProjects(projects): void {
        this.projects = projects;
        if (projects.length > 1) {
            return
        } else {
            // First check if the project has already been set
            const project = JSON.parse(localStorage.getItem('selectedProject'))
            if (!project) {
                this.userService.onSelectProjectAndRedirect(projects[0]);
            }
        }
    }

    selectProject(project): void {
        this.selectedProject = project
    }

    isLoggedIn() {
        const loginCookie = localStorage.getItem('loggedIn')
        return (loginCookie != null && loginCookie === 'true')
    }

    onReturnHome() {
        this.authService.logoutUser()
    }

    closeModal() {
        document.getElementById('SwitchDashboardOverlay').style.display = 'none'
        document.getElementById('AppContainer').classList.remove('blur')
        // this.location.back();
    }
}
