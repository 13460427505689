
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';

@Injectable()
export class HttpHelperService {
	
	constructor(private http: Http) {}

	headers(auth: Boolean = false, token: String = '') {
		let headers = new Headers()
		headers.append('Content-Type', 'application/json')
		headers.append('Accept', 'application/json')
		headers.append('Cache-Control', 'no-store');
        headers.append('Pragma', 'no-cache');
        if (auth) {
            headers.append('Authorization', 'Bearer ' + token);
        }
        return headers;
	}

	handleErrors(error: Response) {
		return observableThrowError(error)
	}
}