import {
    Component,
    OnInit,
    Input
} from '@angular/core';
import {
    SalesDataService
} from 'app/shared/sales-data.service';
import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators
} from '@angular/forms';
import {
    LoadingScreenService
} from 'app/shared/loading-screen.service';
import {
    Chart
} from 'chart.js';
import * as moment from 'moment';
import {
    AuthService
} from 'app/shared/auth.service';
import {
    UsersService
} from 'app/shared/users.service';
import { MessageService } from 'app/shared/message.service';
import { Router } from '@angular/router';
import { isDefaultChangeDetectionStrategy } from '@angular/core/src/change_detection/constants';

@Component({
    selector: "app-sales-data",
    templateUrl: "./sales-data.component.html"
})
export class SalesDataComponent implements OnInit {
    salesForm: FormGroup;
    salesData: any;
    salesDataDetails: Array<any> = [];
    previousMonthSalesData: any;
    differenceData: any = {};
    salesChart: Chart;
    currentUser: any;
    project: any;
    chartType: string = "sales";
    noData: boolean = false;
    showGraphMode: boolean = true;
    @Input() compactView: boolean = false;
    @Input() showReportComponents: boolean = false;

    dateRange: string;
    currentMonth: string;

    pageTitle: string = "Website Sales Data";
    pageDescription: string = `Get a snapshot of your online store’s performance through the sales data from your WooCommerce or Shopify eCommerce platform. View the data as a graph, or select the list format to view sales by day.`;

    lastUpdated: any;
    logoPath: string;

    constructor(
        private salesDataService: SalesDataService,
        private fBuilder: FormBuilder,
        private loadingScreenService: LoadingScreenService,
        private authService: AuthService,
        private userService: UsersService,
        private messageService: MessageService,
        private router: Router
    ) {}

    ngOnInit() {
        let now = moment();
        this.currentMonth = now.format("MMM");

        this.loadingScreenService.startLoading();
        const project = JSON.parse(localStorage.getItem("selectedProject"));
        if (project) {
            this.project = project;
            this.logoPath = project.iconPath;
            this.refreshData();
        }

    }

    onDateChanged(obj: any) {
        this.salesForm = obj.dateForm;
        this.dateRange = obj.dateRange;
        this.refreshData();
    }

    updateDate(): void {
        this.lastUpdated = moment().format("LLLL");
        this.messageService.setMessage(this.lastUpdated);
    }

    refreshData(): void {
        let timeout = setInterval(() => {
            if (this.salesForm && this.salesForm.value.startDate) {
                this.getSalesReport()
                this.getSalesReportPreviousMonth();
                clearInterval(timeout);
            }
        }, 10);
    }

    setDisplayMode(mode: boolean) {
        this.showGraphMode = mode;
        if (mode) {
            setTimeout(() => {
                this.renderMainChart();
            }, 200);
        }
    }

    onDownloadReport(): void {
        this.loadingScreenService.startLoading();
        const domId = ["salesReport1", "salesReport2"];
        const fileName = "Sales_Report.pdf";
        this.showReportComponents = true;
        this.lastUpdated = moment().format("LLLL");
        setTimeout(() => {
            this.userService.generatePDF(domId, fileName).then(
                () => {
                    this.showReportComponents = false;
                    this.loadingScreenService.stopLoading();
                },
                err => {
                    this.showReportComponents = false;
                    this.loadingScreenService.stopLoading();
                }
            );
        }, 300);
    }

    getSalesReport() {
        this.loadingScreenService.startLoading();
        let startDate = this.salesForm.value.startDate;
        let endDate = this.salesForm.value.endDate;

        this.salesDataService
            .getSalesReport(this.project, startDate, endDate)
            .subscribe(
                result => {
                    this.salesData = result.json()[0];
                    this.salesDataDetails = [];
                    this.currentMonth = moment(this.salesForm.value.startDate).format("MMM")
                    if (!this.salesData) {
                        this.noData = true;
                        this.loadingScreenService.stopLoading();
                        return;
                    }

                    let keys = Object.keys(this.salesData.totals);
                    keys.forEach(key => {
                        let obj = {
                            date: key,
                            sales: this.salesData.totals[key].sales,
                            orders: this.salesData.totals[key].orders,
                            items: this.salesData.totals[key].items,
                            tax: this.salesData.totals[key].tax,
                            shipping: this.salesData.totals[key].shipping,
                            customers: this.salesData.totals[key].customers,
                            discount: this.salesData.totals[key].discount
                        };
                        this.salesDataDetails.push(obj);
                    });
                    console.log('data', this.salesData)

                    setTimeout(() => {
                        if (!this.compactView) {
                            this.renderMainChart();
                        }
                    }, 200);
                },
                err => {
                    console.log(err);
                }
            );
    }

    findDifference(): void {
        this.differenceData = {}
        console.log('sales data', this.salesData, 'previousData', this.previousMonthSalesData)
        for (let item in this.salesData) {
            let percentage = this.getPercentageChange(
                this.previousMonthSalesData[item],
                this.salesData[item]
            );
            console.log(percentage)
            if (isNaN(percentage)) {
                this.differenceData[item] = 0
            } else {
                const symbol = percentage.toString().includes('-') ? '' : '+'
                this.differenceData[item] = `${symbol}${Math.round(percentage)}%`
            }
        }
        console.log(this.differenceData)
    }

    getPercentageChange(oldNumber, newNumber): number{
        let decreaseValue = newNumber - oldNumber;
        return (decreaseValue / oldNumber) * 100;
    }

    getSalesReportPreviousMonth() {
        if (this.dateRange === "Last Month") {
            const startDate = this.salesForm.value.startDate;
            const endDate = this.salesForm.value.endDate;
            const previousMonthStartDate = moment(startDate)
                .subtract(1, "month")
                .startOf('month')
                .format("YYYY-MM-DD");
            const previousMonthEndDate = moment(endDate)
                .subtract(1, "month")
                .endOf('month')
                .format("YYYY-MM-DD");
            console.log('previous dates', previousMonthStartDate, previousMonthEndDate)
            this.salesDataService
                .getSalesReport(this.project, previousMonthStartDate, previousMonthEndDate)
                .subscribe(
                    result => {
                        this.previousMonthSalesData = result.json()[0];
                        console.log('previousmonth data',this.previousMonthSalesData)
                        this.findDifference()
                    },
                    err => {
                        console.log(err);
                    }
                );
        }
    }

    renderMainChart() {
        let data = this.getDataForChartType(this.chartType);
        this.renderChart("salesChartCanvas", data);
        this.updateDate();
        this.loadingScreenService.stopLoading();
    }

    renderChart(id, chartData) {
        let config: any = {
            type: "bar",
            data: chartData,
            options: {
                maintainAspectRatio: false,
                legend: {
                    position: "left",
                    display: false
                },
                scales: {
                    xAxes: [
                        {
                            type: "time",
                            time: {
                                unit:
                                    this.dateRange === "This Year"
                                        ? "month"
                                        : "day",
                                round:
                                    this.dateRange === "This Year"
                                        ? "month"
                                        : "day",
                                displayFormats:
                                    this.dateRange === "This Year"
                                        ? { month: "MMM" }
                                        : { day: "MMM D" }
                            },
                            gridLines: {
                                drawOnChartArea: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ]
                }
            }
        };

        let canvas = document.getElementById(id) as HTMLCanvasElement;
        if (!canvas) {
            return;
        }
        let ctx = canvas.getContext("2d");
        if (this.salesChart) {
            this.salesChart.destroy();
            this.salesChart = new Chart(ctx, config);
        } else {
            this.salesChart = new Chart(ctx, config);
        }
    }

    showChart(chartType) {
        this.chartType = chartType;
        this.updateChart(chartType);
    }

    updateChart(chartType) {
        let data = this.getDataForChartType(chartType);
        this.salesChart.data = data;
        this.salesChart.update();
    }

    getDataForChartType(chartType) {
        let labels = [];
        let chartData = [];
        let borderColor = "";
        let backgroundColor = "";
        let label = "";
        this.salesDataDetails.forEach(element => {
            let dateStr = element.date;
            let d = new Date();
            let year = dateStr.substring(0, 4);
            let month = parseInt(dateStr.substring(5, 7)) - 1;
            let day = dateStr.substring(8, dateStr.length);
            if (day === "") {
                day = 1;
            }
            d.setFullYear(year);
            d.setDate(day);
            d.setMonth(month);
            let date = moment(d).format("MMM DD");
            labels.push(date);
            switch (chartType) {
                case "sales":
                    chartData.push(element.sales);
                    borderColor = "#a3a0fb";
                    backgroundColor = "#a3a0fb";
                    label = "Sales";
                    break;
                case "orders":
                    chartData.push(element.orders);
                    borderColor = "#55d8fe";
                    backgroundColor = "#55d8fe";
                    label = "Orders";
                    break;
                case "items":
                    chartData.push(element.items);
                    borderColor = "#ff4449";
                    backgroundColor = "#ff4449";
                    label = "Items";
                    break;
                case "shipping":
                    chartData.push(element.shipping);
                    borderColor = "#ffd983";
                    backgroundColor = "#ffd983";
                    label = "Shipping";
                    break;
                case "discount":
                    chartData.push(element.discount);
                    borderColor = "#009245";
                    backgroundColor = "#009245";
                    label = "Discount";
                    break;
            }
        });

        let data = {
            labels: labels,
            datasets: [
                {
                    data: chartData,
                    label: label,
                    borderColor: borderColor,
                    backgroundColor: backgroundColor
                }
            ]
        };

        return data;
    }

    viewMore() {
        this.router.navigate(["/sales-data"]);
    }
}
