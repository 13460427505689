import { Injectable } from '@angular/core';
import { Http, Response } from "@angular/http";
import { HttpHelperService } from "./http-helper.service";
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class SalesDataService {
	constructor(private http: Http, private httpHelperService: HttpHelperService) {}

	getSalesReport(project, startDate, endDate) {
		// Setting rockagency to onedaybridal for demo purposes
		if (project.url === "https://rockagency.com.au/") {
			project._id = '5cc91539e487e3d64a54dae2'
			project.url = "https://onedaybridal.com.au/";
		}
        console.log(project._id, project.url);
		const payload = {
			projectId: project._id,
			url: project.url,
			startDate,
			endDate
		}

		return this.http.post('/api/sales-data/reports/sales', payload, {headers: this.httpHelperService.headers(true, AuthService.token)})
	}
}
