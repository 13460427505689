import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PerformancePanelComponent } from "./performance-panel/performance-panel.component";
import { AuthGuard } from 'app/shared/auth-guard.service';

const performanceRoutes: Routes = [
  {
    path: '',
		component: PerformancePanelComponent,
		canActivate: [AuthGuard],
  }
];

@NgModule({
	imports: [
		RouterModule.forChild(performanceRoutes)
	],
	exports: [
		RouterModule
	]
})
export class PerformanceRoutingModule {

}