import {
	Component,
	OnInit
} from '@angular/core';
import {
	NgForm,
	FormBuilder,
	FormGroup,
	FormControl,
	Validators
} from '@angular/forms';
import {
	AuthService
} from 'app/shared/auth.service';
import {
	Router, ActivatedRoute
} from '@angular/router';
import { UsersService } from 'app/shared/users.service';
import { CheckEmail } from '../shared/validators/check-email.validator';
import { Gtag } from 'angular-gtag';
declare const gtag: Function;

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html"
})
export class LoginComponent implements OnInit {
    loginError: any;
    loginFormOpen: boolean = false;
    landingOpen: boolean = true;
    loginForm: FormGroup;
    currentUser: any;
    errors: Array<any> = [];
    verifyStatus: String = ''
    messages: Array<any> = []
    projects: any

    constructor(
        private fBuilder: FormBuilder,
        private authService: AuthService,
        private userService: UsersService,
        private router: Router,
        private route: ActivatedRoute,
        private gtag: Gtag
    ) {}

    ngOnInit() {
        this.loginForm = this.fBuilder.group({
            email: new FormControl(
                null,
                Validators.compose([Validators.required, CheckEmail])
            ),
            password: new FormControl(
                null,
                Validators.compose([Validators.required])
            )
        });

        this.errors = []
        this.messages = []

        this.verifyStatus = this.route.snapshot.params['id']
        if (this.verifyStatus) {
            switch (this.verifyStatus) {
                case 'verify-success':
					this.messages.push({ message: 'Account verified successfully' });
					break;
				case 'verify-invalid':
					this.errors.push({ message: 'Invalid or expired account verification link' });
					break;
				case 'reset-success':
					this.messages.push({ message: 'Password reset successful' });
					break;
				case 'reset-invalid':
					this.errors.push({ message: 'Invalid or expired password reset link' });
					break;
				default:
					this.router.navigate(['/login']);
            }
        }
    }

    onLogin(): void {
        const email = this.loginForm.value.email;
        const password = this.loginForm.value.password;
        if (this.loginForm.valid) {
            // User Tracking Troy
            console.log(`log in ${email}`)
            if (email === 'troy.townsend@tigerpistol.com.au') {
                // gtag("set", { user_id: "Troy_Townsend" });
                gtag('event', 'login', { method : 'Google' });
                this.gtag.event("login", {
                    method: "Instagram",
                    event_category: "engagemnt",
                    event_label: "New user logged in via OAuth"
                });
            }

            this.authService.loginUser(email, password).subscribe(
                (response: any) => {
                    this.userService
                        .getUserProjects()
                        .then(projects => {
                            if (projects.length > 1) {
                                this.projects = projects;
                                this.router.navigate([
                                    "/select-project"
                                ]);
                            } else {
                                this.userService.onSelectProjectAndRedirect(
                                    projects[0]
                                );
                            }
                        });
                },
                (error: any) => {
                    this.errors = error.json();
                }
            );
        }
    }

    openLoginForm(): void {
        this.loginFormOpen = true;
        this.landingOpen = false;
    }

    openLandingPage(): void {
        this.loginFormOpen = false;
        this.landingOpen = true;
    }
}
