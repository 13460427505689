import { AbstractControl } from '@angular/forms'

export function CheckPassword(control: AbstractControl) {
	if (control.value && control.value !== '') {
		if (control.value.length < 8) {
			return { 'invalidPassword': true }
		} else if (!/[A-Z]/.test(control.value)) {
			return { 'invalidPassword': true }
		} else if (!/\d/.test(control.value) ) {
            return { 'invalidPassword': true };
        }
	}
	return null
}